import {
  equipmentConstraintsConfigurationAPIs,
  entity,
  mduAPIs,
} from '@smf/ui-util-app';

export const queryConstants = {
  GET_HEADER_DATA: 'GET_HEADER_DATA',
  GET_CONSTRAINTS_STATUS_LIST: 'GET_CONSTRAINTS_STATUS_LIST',
  GET_CARD_CONSTRAINTS_DATA: 'GET_CARD_CONSTRAINTS_DATA',
  GET_CONSTRAINTS_TABLE_DATA: 'GET_CONSTRAINTS_TABLE_DATA',
  GET_VIEW_EQUIPMENT_FAULT_DATA: 'GET_VIEW_EQUIPMENT_FAULT_DATA',
  GET_NOTES_DATA: 'GET_NOTES_DATA',
  GET_SNOOZE_TIME_DATA: 'GET_SNOOZE_TIME_DATA',

  GET_WORK_ORDER_LIST_DATA: 'GET_WORK_ORDER_LIST_DATA',
  GET_WORK_ORDER_DETAILS_DATA: 'GET_WORK_ORDER_DETAILS_DATA',
  GET_WORK_ORDER_STATUS: 'GET_WORK_ORDER_STATUS',

  GET_CONFIG_STATUSES: 'GET_CONFIG_STATUSES',
  GET_USERS_DATA: 'GET_USERS_DATA',

  //mdu query constants
  GET_MDU_SOURCE_DATA: 'GET_MDU_SOURCE_DATA',
  GET_MDU_GATEWAY_TABLE_DATA: 'GET_MDU_GATEWAY_TABLE_DATA',
  GET_MDU_CONFIG_ADD: 'GET_MDU_CONFIG_ADD',
  DELETE_MDU_GATEWAY: 'GET_MDU_CONFIG_ADD',
};

//MDU APIS
export function retrieveMDUSourceData(params) {
  return mduAPIs.getMDUSourceData(params);
}

export function mduConfigAdd(params) {
  return mduAPIs.mduConfigAdd(params);
}

export function retrieveMDUGatewayTableData(params) {
  return mduAPIs.getMDUGatewayTableData(params);
}
export async function updateMDUGatewayData(data) {
  return mduAPIs.postEditMDUGatewayData(data);
}
export async function updateMDUGatewayStatusData(data) {
  return mduAPIs.postMDUGatewayStatusData(data);
}

export function getConstraintsD2DHeaderData(params) {
  return equipmentConstraintsConfigurationAPIs.getConstraintsHeaderData(params);
}

export function retrieveContraintsStatusList(params) {
  return equipmentConstraintsConfigurationAPIs.getContraintStatusList(params);
}

export function retrieveCardContraintsData(params) {
  return equipmentConstraintsConfigurationAPIs.getCardContraintsData(params);
}

export function getConstraintData(params) {
  return equipmentConstraintsConfigurationAPIs.getConstraintData(params);
}

export function retrieveViewEquipmentFaultData(params) {
  return equipmentConstraintsConfigurationAPIs.getViewEquipmentFaultData(
    params
  );
}

export async function sendSnoozeConstraintData(data) {
  return equipmentConstraintsConfigurationAPIs.postSnoozeConstraintData(data);
}

export async function sendReopenSnoozeConstraintData(data) {
  return equipmentConstraintsConfigurationAPIs.postReopenSnoozeConstraintData(
    data
  );
}

export function retrieveSnoozeTimeData(params) {
  return equipmentConstraintsConfigurationAPIs.getSnoozeTimeData(params);
}

export function getNotesData(params) {
  return equipmentConstraintsConfigurationAPIs.getNotesData(params);
}

export async function sendNotesData(data) {
  return equipmentConstraintsConfigurationAPIs.postNotesData(data);
}

// ? work order apis

export function retrieveWorkOrderListData(params) {
  return equipmentConstraintsConfigurationAPIs.getWorkOrderListData(params);
}

export function retrieveWorkOrderDetailsData(params) {
  return equipmentConstraintsConfigurationAPIs.getWorkOrderDetailsData(params);
}

export function retrieveWorkOrderStatus(params) {
  return equipmentConstraintsConfigurationAPIs.getWorkOrderStatus(params);
}

export function postWorkOrder(data) {
  return equipmentConstraintsConfigurationAPIs.postCreateWorkOrder(data);
}

export async function getFactoryConfigStatuses(entityId) {
  return await equipmentConstraintsConfigurationAPIs.getConfigStatusData({
    plantId: entityId,
  });
}

export async function getUserById() {
  const result = await entity.getEntities({
    entityType: 'Plant',
    globeCheck: true,
  });

  return result;
}
