import { Typography, Switch, styled } from '@mui/material';
import MDUActionsMenu from '../../components/MDUActionsMenu';
import { MDU_CONNECTIVITY_CONSTANTS } from '../../constants';

const TableHeaderTypography = styled(Typography)(() => ({
  fontFamily: 'Open Sans',
  fontSize: '0.875rem',
  fontWeight: 400,
  lineHeight: '1.5rem',
  letterSpacing: '0.0106rem',
  textAlign: 'left',
  whiteSpace: 'nowrap !important',
  textOverflow: 'ellipsis',
  overflow: 'hidden',
  width: '8.375rem',
}));

export const MDUTableColumns = (onActionClick) => [
  {
    accessorKey: 'gatewayName',
    cell: (info) => (
      <TableHeaderTypography>
        {info.getValue() === '-' ? '-' : `${info.getValue()}`}
      </TableHeaderTypography>
    ),
    header: 'Gateway Name',
    sortDescFirst: false,
    width: '15%',
    sortingFn: 'alphanumeric',
  },
  {
    accessorKey: 'gatewayDescription',
    cell: (info) => (
      <TableHeaderTypography>
        {info.getValue() === '-' ? '-' : `${info.getValue()}`}
      </TableHeaderTypography>
    ),

    header: 'Description',
    width: '19.5%',
    sortingFn: 'alphanumeric',
  },
  {
    accessorKey: 'authentication',
    cell: (info) => (
      <TableHeaderTypography>
        {info.getValue() === '-' ? '-' : `${info.getValue()}`}
      </TableHeaderTypography>
    ),

    header: 'Authenticated',
    width: '9%',
    sortingFn: 'alphanumeric',
  },
  {
    accessorKey: 'topicsDiscovered',
    cell: (info) => (
      <TableHeaderTypography>
        {info.getValue() === '-' ? '-' : `${info.getValue()}`}
      </TableHeaderTypography>
    ),

    header: 'Topics Found',
    width: '13%',
    sortingFn: 'alphanumeric',
  },
  {
    accessorKey: 'tagsDiscovered',
    cell: (info) => (
      <TableHeaderTypography>
        {info.getValue() === '-' ? '-' : `${info.getValue()}`}
      </TableHeaderTypography>
    ),

    header: 'Metrics',
    width: '7%',
    sortingFn: 'alphanumeric',
  },

  {
    accessorKey: 'gatewayStatus',
    cell: (info) => (
      <Switch
        data-testid="toggle_switch"
        sx={(theme) => ({
          margin: '-0.5rem',
          ['& .MuiSwitch-thumb']: {
            //background: info.getValue() ? '#007CB0' : '#BDBDBD',
            background: info.getValue()
              ? theme.palette.primary.main
              : theme.palette.grey.grey300,
          },
          ['& .MuiSwitch-track']: {
            // background: info.getValue()
            //   ? '#007CB0'
            //   : 'rgba(255, 255, 255, 0.87)',
            borderRadius: '10px',
            background: info.getValue()
              ? theme.palette.primary.main
              : theme.palette.text.primary,
          },
        })}
        checked={info.getValue()}
        onChange={(e) => {
          onActionClick(
            info.row.original,
            MDU_CONNECTIVITY_CONSTANTS.ACTIONS.ENABLE_DISABLE,
            e.target.checked
          );
        }}
      />
    ),

    header: 'Status',
    width: '7%',
    sortingFn: 'basic',
  },
  {
    accessorKey: 'gatewaySource',
    cell: (info) => (
      <TableHeaderTypography>
        {info.getValue() === '-' ? '-' : `${info.getValue()}`}
      </TableHeaderTypography>
    ),

    header: 'Gateway Source',
    width: '15%',
    sortingFn: 'alphanumeric',
  },
  {
    accessorKey: 'certsDateTime',
    cell: (info) => (
      <TableHeaderTypography>
        {info.getValue() === '-' ? '-' : `${info.getValue()}`}
      </TableHeaderTypography>
    ),

    header: 'Generated On',
    width: '13%',
    sortingFn: 'alphanumeric',
  },
  {
    accessorKey: 'actions',
    cell: (info) => (
      <TableHeaderTypography>
        <MDUActionsMenu onActionClick={onActionClick} row={info.row.original} />
      </TableHeaderTypography>
    ),
    enableSorting: false,
    header: '',
    width: '2%',
    hideDivider: 'true',
    sortingFn: 'alphanumeric',
  },
];
