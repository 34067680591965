import momentTimezone from 'moment-timezone';
/* eslint-disable no-unused-vars */
import {
  MDU_CONNECTIVITY_CONSTANTS,
  CONNECTIVITY_MODALS_CONSTANTS,
  WORK_ORDER_LIST_CONSTANTS,
} from '../constants';
import { CONSTANT_MESSAGES } from '../constants/en-us';

export function timeConvert(num) {
  // num in minutues from BE
  const hours = num / 60;
  const rhours = Math.floor(hours);
  const minutes = (hours - rhours) * 60;
  const rminutes = Math.round(minutes);

  if (rhours > 23) {
    const days = Math.floor(hours / 24);
    const mins = num - rhours * 60;
    let dayHours = rhours - days * 24;
    if (mins > 29) {
      dayHours += 1;
    }
    if (dayHours < 11) {
      dayHours = `0${dayHours}`;
    }
    return `${days} ${days > 1 ? 'days' : 'day'} ${dayHours} ${
      dayHours > 1 ? 'hrs' : 'hr'
    }`;
  }

  return `${rhours < 11 ? `0${rhours}` : rhours} ${
    rhours > 1 ? 'hrs' : 'hr'
  } ${rminutes} ${rminutes > 1 ? 'mins' : 'min'}`;
}

export function isStringEmpty(str) {
  return !str || str.length === 0;
}

export const formatCardResponseAPI = (response) => {
  return {
    actualOpenConstraints:
      response.actualOpenConstraints < 10
        ? `${'0'}${response.actualOpenConstraints}`
        : `${response.actualOpenConstraints}`,

    targetOpenConstraints:
      response.targetOpenConstraints < 10
        ? `${'0'}${response.targetOpenConstraints}`
        : `${response.targetOpenConstraints}`,

    actualSnoozedConstraints:
      response.actualSnoozedConstraints < 10
        ? `${'0'}${response.actualSnoozedConstraints}`
        : `${response.actualSnoozedConstraints}`,

    actualClosedConstraints:
      response.actualClosedConstraints < 10
        ? `${'0'}${response.actualClosedConstraints}`
        : `${response.actualClosedConstraints}`,

    targetClosedConstraints:
      response.targetClosedConstraints < 10
        ? `${'0'}${response.targetClosedConstraints}`
        : `${response.targetClosedConstraints}`,

    openConstraintsDuration: {
      hr:
        Math.floor(response?.openConstraintsDuration / 60) < 10
          ? `${'0'}${Math.floor(response?.openConstraintsDuration / 60)}`
          : `${Math.floor(response?.openConstraintsDuration / 60)}`,
      min:
        Math.round(response?.openConstraintsDuration % 60) < 10
          ? `${'0'}${Math.round(response?.openConstraintsDuration % 60)}`
          : `${Math.round(response?.openConstraintsDuration % 60)}`,
    },

    targetOpenConstraintDuration: {
      hr:
        Math.floor(response?.targetOpenConstraintDuration / 60) < 10
          ? `${'0'}${Math.floor(response?.targetOpenConstraintDuration / 60)}`
          : `${Math.floor(response?.targetOpenConstraintDuration / 60)}`,
      min:
        Math.round(response?.targetOpenConstraintDuration % 60) < 10
          ? `${'0'}${Math.round(response?.targetOpenConstraintDuration % 60)}`
          : `${Math.round(response?.targetOpenConstraintDuration % 60)}`,
    },

    avgConstraintResolutionTime: {
      hr:
        Math.floor(response?.avgConstraintResolutionTime / 60) < 10
          ? `${'0'}${Math.floor(response?.avgConstraintResolutionTime / 60)}`
          : `${Math.floor(response?.avgConstraintResolutionTime / 60)}`,
      min:
        Math.round(response?.avgConstraintResolutionTime % 60) < 10
          ? `${'0'}${Math.round(response?.avgConstraintResolutionTime % 60)}`
          : `${Math.round(response?.avgConstraintResolutionTime % 60)}`,
    },

    targetAvgConstraintResolutionTime: {
      hr:
        Math.floor(response?.targetAvgConstraintResolutionTime / 60) < 10
          ? `${'0'}${Math.floor(
              response?.targetAvgConstraintResolutionTime / 60
            )}`
          : `${Math.floor(response?.targetAvgConstraintResolutionTime / 60)}`,
      min:
        Math.round(response?.targetAvgConstraintResolutionTime % 60) < 10
          ? `${'0'}${Math.round(
              response?.targetAvgConstraintResolutionTime % 60
            )}`
          : `${Math.round(response?.targetAvgConstraintResolutionTime % 60)}`,
    },

    forecastedLabourTime: {
      hr:
        Math.floor(response?.forecastedLabourTime / 60) < 10
          ? `${'0'}${Math.floor(response?.forecastedLabourTime / 60)}`
          : `${Math.floor(response?.forecastedLabourTime / 60)}`,
      min:
        Math.round(response?.forecastedLabourTime % 60) < 10
          ? `${'0'}${Math.round(response?.forecastedLabourTime % 60)}`
          : `${Math.round(response?.forecastedLabourTime % 60)}`,
    },

    targetForecastedLabourTime: {
      hr:
        Math.floor(response?.targetForecastedLabourTime / 60) < 10
          ? `${'0'}${Math.floor(response?.targetForecastedLabourTime / 60)}`
          : `${Math.floor(response?.targetForecastedLabourTime / 60)}`,
      min:
        Math.round(response?.targetForecastedLabourTime % 60) < 10
          ? `${'0'}${Math.round(response?.targetForecastedLabourTime % 60)}`
          : `${Math.round(response?.targetForecastedLabourTime % 60)}`,
    },
  };
};

export const getMultiplier = (unit) => {
  switch (unit?.toLowerCase()) {
    case 'hours':
      return 1000 * 60 * 60;
    case 'minutes':
      return 1000 * 60;
    case 'seconds':
      return 1000;
    default:
      return 1000 * 60;
  }
};

//  ? this will check for string with whitespaces as well.
export const isBlankString = (str) => {
  return !str || str.length === 0 || /^\s*$/.test(str);
};

export const getLastAppliedFilterValue = (label) => {
  switch (label) {
    case WORK_ORDER_LIST_CONSTANTS.WORK_ORDER:
      return 'workOrderId';

    case WORK_ORDER_LIST_CONSTANTS.WO_TYPE:
      return 'type';

    case WORK_ORDER_LIST_CONSTANTS.WO_STATUS:
      return 'status';
    case WORK_ORDER_LIST_CONSTANTS.WO_COMPLETITION:
      return 'completionTime';

    default:
      return '';
  }
};

export const removeItemAll = (arr, value) => {
  var i = 0;
  while (i < arr.length) {
    if (arr[i] === value) {
      arr.splice(i, 1);
    } else {
      ++i;
    }
  }
  return arr;
};

export const checkAlphaNumericField = (val) => {
  const regEx = /^[0-9a-zA-Z]+$/;
  return val.match(regEx);
};
export const checkSpaceAndAlphaNumericField = (val) => {
  const regEx = /^[0-9a-zA-Z ]+$/;
  return val.match(regEx);
};

export const booleanToStringConverter = (val) => {
  return val === true ? 'Yes' : 'No';
};

export const checkNewGatewayInputError = (
  field,
  val,
  buttonClicked = false,
  gatewayList = [],
  selectedRowForActions = { row: null, actionType: '' }
) => {
  if (buttonClicked && !val) {
    return CONSTANT_MESSAGES.MDU.REQUIRED_FIELDS;
  }
  if (
    field === CONNECTIVITY_MODALS_CONSTANTS.FIELD_NAME &&
    !!val &&
    !checkAlphaNumericField(val)
  ) {
    return CONSTANT_MESSAGES.MDU.ALPHANUMERIC_ERROR;
  }
  if (
    field !== CONNECTIVITY_MODALS_CONSTANTS.FIELD_NAME &&
    !!val &&
    !checkSpaceAndAlphaNumericField(val)
  ) {
    return CONSTANT_MESSAGES.MDU.ALPHANUMERIC_ERROR;
  }

  if (val?.length > 300) {
    return CONSTANT_MESSAGES.MDU.MAX_LENGTH_ERROR;
  }
  //? condition to check val with existing gateway names
  // eslint-disable-next-line no-constant-condition
  if (field === CONNECTIVITY_MODALS_CONSTANTS.FIELD_NAME) {
    // ? check duplication against any existing names for add gateway and for edit gateway check duplication against any existing names when selected row gatewayName is not same as input field value
    const isDuplicateGatewayName = gatewayList?.some(
      (gateway) =>
        selectedRowForActions?.row?.gatewayName?.toLowerCase() !==
          val?.toLowerCase() &&
        gateway.gatewayName?.toLowerCase() === val?.toLowerCase()
    );

    // ? returns duplication error for add and edit gateway modal name input field
    return isDuplicateGatewayName
      ? CONSTANT_MESSAGES.MDU.DUPLICATE_GATEWAY_NAME
      : '';
  }
  return '';
};

const updateGatewayStatus = (value) => {
  switch (value?.toLowerCase()) {
    case 'enabled':
      return true;
    case 'disabled':
      return false;

    default:
      return false;
  }
};

export const formatGatewayDatafromAPI = (gatewayTableData) => {
  const formattedRows = gatewayTableData.map((gateway) => {
    return {
      ...gateway,
      gatewayId: gateway.gatewayId,
      factoryId: gateway.factoryId,
      gatewayName: gateway.gatewayName ?? '-',
      gatewayDescription: gateway.gatewayDescription ?? '-',
      gatewaySource: gateway.gatewaySource ?? '-',
      gatewayStatus: updateGatewayStatus(gateway.gatewayStatus),
      authentication: booleanToStringConverter(gateway.authentication ?? false),
      topicsDiscovered: booleanToStringConverter(
        gateway.topicsDiscovered ?? false
      ),
      tagsDiscovered: booleanToStringConverter(gateway.tagsDiscovered ?? false),
      plantThingGroup: gateway.plantThingGroup ?? '-',
      thingArn: gateway.thingArn ?? '-',
      certsArn: gateway.certsArn ?? '-',
      policyName: gateway.policyName ?? '-',
      certsDateTime: momentTimezone(gateway.certsDateTime * 1000).format(
        'MM/DD/YYYY'
      ),
      metrics: booleanToStringConverter(gateway.metrics),
    };
  });

  return formattedRows;
};

//? to clear all the mdu modal states after closing modal
export const closeModalAndClearStates = (
  setIsModalOpen,
  setButtonClicked,
  setSelectedDropdown,
  setName,
  setDescription,
  setIsEdit,
  setSelectedRowForActions
) => {
  setIsModalOpen(false);
  setButtonClicked(false);
  setSelectedDropdown('');
  setName('');
  setDescription('');
  setIsEdit(false);
  setSelectedRowForActions({
    row: null,
    actionType: '',
  });
};

// ? function to download mdu files
export const downloadFiles = async (file1, file2) => {
  await window.open(file1, '_self');
  // ? NOTE: not in scope for PI-3 . re enable commented code below, once the doc url is coming in
  // await window.open(file2, '_self');
};

//? return table gateway data
export const setGatewayTableRows = (gatewayTableData) => {
  return gatewayTableData;
};

// ? handle and redirect actions from table rows
export const onActionClickHelper = (
  row,
  actionType,
  isGatewayEnabled,
  setIsEdit = () => {},
  setSelectedRowForActions = () => {},
  setSelectedDropdown = () => {},
  setName = () => {},
  setDescription = () => {},
  setIsModalOpen = () => {},
  updateTableDataWithNewRow = () => {},
  setIsConfirmModalOpen = () => {}
) => {
  let updatedRow = row;

  switch (actionType) {
    case MDU_CONNECTIVITY_CONSTANTS.ACTIONS.REFRESH_CERTIFICATE:
      // ? trigger the websocket here
      // ? NOTE: this functionality is pending from BE
      break;
    case MDU_CONNECTIVITY_CONSTANTS.ACTIONS.EDIT:
      // ? open the edit modal
      setIsEdit(true);
      // ? update the selected row
      setSelectedRowForActions({
        row: row,
        actionType: MDU_CONNECTIVITY_CONSTANTS.ACTIONS.EDIT,
      });

      //? open edit modal having prefilled selected row values
      setSelectedDropdown(row.gatewaySource);
      setName(row.gatewayName);
      setDescription(row.gatewayDescription);
      setIsModalOpen(true);
      break;
    case MDU_CONNECTIVITY_CONSTANTS.ACTIONS.DELETE:
      // ?update table data to re-render the UI
      updateTableDataWithNewRow(
        updatedRow,
        MDU_CONNECTIVITY_CONSTANTS.ACTIONS.DELETE
      );
      // ? update the selected row
      setSelectedRowForActions({
        row: updatedRow,
        actionType: MDU_CONNECTIVITY_CONSTANTS.ACTIONS.DELETE,
      });

      // ? open the delete modal
      setIsConfirmModalOpen(true);

      break;
    case MDU_CONNECTIVITY_CONSTANTS.ACTIONS.ENABLE_DISABLE:
      // ? toggle the rows gateway status
      updatedRow = { ...row, gatewayStatus: isGatewayEnabled };
      // ? update table data to re-render the UI
      updateTableDataWithNewRow(
        updatedRow,
        MDU_CONNECTIVITY_CONSTANTS.ACTIONS.ENABLE_DISABLE
      );

      // ? update the selected row
      setSelectedRowForActions({
        row: updatedRow,
        actionType: MDU_CONNECTIVITY_CONSTANTS.ACTIONS.ENABLE_DISABLE,
      });

      // ? open the enable/disable modal
      setIsConfirmModalOpen(true);
      break;
    default:
      break;
  }
};

export const createEnableDisableParams = (selectedRowForActions, rxjsState) => {
  return {
    action: 'Status',
    factoryId: selectedRowForActions?.row?.factoryId,
    gatewayId: selectedRowForActions?.row?.gatewayId,
    gatewayStatus: selectedRowForActions?.row?.gatewayStatus
      ? 'Enabled'
      : 'Disabled',
    plantThingGroup: selectedRowForActions?.row?.plantThingGroup,
    thingArn: selectedRowForActions?.row?.thingArn,
    certsArn: selectedRowForActions?.row?.certsArn,
    policyName: selectedRowForActions?.row?.policyName,
  };
};

export const createEditParams = (params, selectedRowForActions, rxjsState) => {
  return {
    ...params,
    action: selectedRowForActions.actionType,
    factoryId: selectedRowForActions.row.factoryId,
    gatewayId: selectedRowForActions.row.gatewayId,
  };
};

export const getCreateGatewayParams = (
  rxjsState,
  name,
  description,
  selectedDropdown,
  authToken
) => {
  return {
    action: 'createGateway',
    factoryId: parseInt(rxjsState?.factoryId),
    gatewayName: name,
    gatewayDescription: description,
    gatewaySource: selectedDropdown,
    authorizationToken: authToken,
  };
};

export const getDeleteGatewayParams = (rxjsState, selectedRowForActions) => {
  return {
    action: 'deleteGateway',
    gatewayId: selectedRowForActions.row?.gatewayId,
    factoryId: parseInt(selectedRowForActions.row?.factoryId),
    plantThingGroup: selectedRowForActions.row?.plantThingGroup,
    thingArn: selectedRowForActions.row?.thingArn,
    certsArn: selectedRowForActions.row?.certsArn,
    policyName: selectedRowForActions.row?.policyName,
  };
};

export const isAlphanumeric = (str) => {
  return /^[a-zA-Z0-9]+$/.test(str);
};

export const isEmptyString = (str) => {
  return !str || str.length === 0;
};
