import { globalstate$, config } from '@smf/ui-util-app';

const authToken = globalstate$.value.sessionToken;
const socketAuthorizationTokens = `${authToken.replace('Bearer ', '')}`;

const WS_ENDPOINT_GATEWAY_CONFIG_ADD_OR_DELETE = `wss://${config.BASE_WS_API_URL}/mduconfigview`;

export const gatewayAddConfigWebSocket = (dataToSend) => {
  return new Promise((reject, resolve) => {
    const ws = new WebSocket(
      WS_ENDPOINT_GATEWAY_CONFIG_ADD_OR_DELETE,
      socketAuthorizationTokens
    );

    ws.onopen = () => {
      if (dataToSend !== null) {
        ws.send(JSON.stringify(dataToSend));
      }
    };
    ws.onmessage = ({ data }) => {
      // ? store the recieved data in  a variable
      const recievedData = JSON.parse(data);

      // ? check for error,
      if (
        recievedData.isError !== undefined ||
        recievedData.isError === false
      ) {
        // ? if no error, reolve the promise and close the socket
        ws.close();
        resolve(recievedData);
      } else {
        // ?  else reject the promise and close the socket
        ws.close();
        reject(recievedData);
      }
    };
    // ?  error connecting the socket then close the socket
    ws.onerror = (err) => {
      ws.close();
      reject(err);
    };
  });
};

export const gatewayDeleteWebSocket = (dataToSend) => {
  return new Promise((reject, resolve) => {
    const ws = new WebSocket(
      WS_ENDPOINT_GATEWAY_CONFIG_ADD_OR_DELETE,
      socketAuthorizationTokens
    );

    ws.onopen = () => {
      if (dataToSend !== null) {
        ws.send(JSON.stringify(dataToSend));
      }
    };
    ws.onmessage = ({ data }) => {
      // ? store the recieved data in  a variable
      const recievedData = JSON.parse(data);

      // ? check for error,
      if (
        recievedData.isError !== undefined ||
        recievedData.isError === false
      ) {
        // ? if no error, reolve the promise and close the socket
        ws.close();
        resolve(recievedData);
      } else {
        // ?  else reject the promise and close the socket
        ws.close();
        reject(recievedData);
      }
    };
    // ?  error connecting the socket then close the socket
    ws.onerror = (err) => {
      ws.close();
      reject(err);
    };
  });
};
