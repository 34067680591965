import { createTheme } from '@mui/material';
import { common } from './common';
import { customBreakpoints } from './responsiveness';
import { customTypography } from './typography';

export const lightPalette = {
  primary: {
    main: '#007CB0',
    light: '#00A3E0',
    dark: '#0076A8',
    contrast: '#FFFFFF',
    background: {
      outlined: '#00A3E080',
      containedHover: '#005376',
      outlinedHover: '#007CB014',
    },
  },
  secondary: {
    main: '#0D8390',
    light: '#0097A9',
    dark: '#007680',
    contrast: '#FFFFFF',
    background: {
      outlined: '#0097A980',
      containedHover: '#00535A',
      outlinedHover: '#0097A914',
    },
  },
  text: {
    primary: '#000000DE',
    secondary: '#00000099',
    disabled: '#00000061',
  },
  action: {
    active: '#0000008A',
    hover: '#0000000A',
    selected: '#00000014',
    disabled: '#00000042',
    disabledBackground: '#0000001F',
    focus: '#0000001F',
  },
  error: {
    main: '#DA291C',
    dark: '#AE2116',
    light: '#E97F77',
    contrast: '#FFFFFF',
    background: {
      main: '#FBEAE8',
      containedHover: '#AB2F26',
      outlinedHover: '#DA291C14',
    },
    border: {
      outlined: '#DA291C80',
    },
    content: '#57100B',
  },
  info: {
    main: '#333333',
    dark: '#000000',
    light: '#666666',
    contrast: '#FFFFFF',
    background: {
      main: '#E5E5E5',
      containedHover: '#000000',
      outlinedHover: '#00000014',
    },
    border: {
      outlined: '#00000080',
    },
    content: '#000000',
  },
  warning: {
    main: '#ED8B00',
    dark: '#BE6F00',
    light: '#F2AE4D',
    contrast: '#FFFFFF',
    background: {
      main: '#FDF3E5',
      containedHover: '#A66100',
      outlinedHover: '#ED8B0014',
    },
    border: {
      outlined: '#ED8B0080',
    },
    content: '#5F2B01',
  },
  success: {
    main: '#86BC25',
    dark: '#5E841A ',
    light: '#9EC951',
    contrast: '',
    background: {
      main: '#F3F8E9',
      containedHover: '#5E841A',
      outlinedHover: '#86BC2514',
    },
    border: {
      outlined: '#86BC2580',
    },
    content: '#364B0F ',
  },
  //    ? NOTE: other generic component styles
  generic: {
    divider: {
      fill: '#0000001F',
    },
    backdrop: {
      overlay: '#00000080',
    },
    rating: {
      active: '#FFB400',
    },
    snackbar: {
      background: '#323232',
    },
    borderOutlined: 'rgba(0, 0, 0, 0.23)',
    borderStandardInputLine: 'rgba(0, 0, 0, 0.42)',
  },
  background: {
    paper: '#FFFFFF',
    default: '#FAFAFA',
  },
  grey: {
    grey50: '#FAFAFA',
    grey100: '#F5F5F5',
    grey200: '#EEEEEE',
    grey300: '#E0E0E0',
    grey400: '#BDBDBD',
    grey500: '#9E9E9E',
    grey600: '#757575',
    grey700: '#616161',
    grey800: '#424242',
    grey900: '#212121',
    greyA100: '#D5D5D5',
    greyA200: '#AAAAAA',
    greyA400: '#616161',
    greyA700: '#303030',
  },
  elevations: {
    outlined: '0px 0px 0px 1px #E0E0E0',
    e1: '0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px rgba(0, 0, 0, 0.14), 0px 1px 3px rgba(0, 0, 0, 0.12)',
    e2: '0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px rgba(0, 0, 0, 0.14), 0px 1px 5px rgba(0, 0, 0, 0.12)',
    e3: '0px 3px 3px -2px rgba(0, 0, 0, 0.2), 0px 3px 4px rgba(0, 0, 0, 0.14), 0px 1px 8px rgba(0, 0, 0, 0.12)',
    e4: '0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px rgba(0, 0, 0, 0.14), 0px 1px 10px rgba(0, 0, 0, 0.12)',
    e5: '0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 5px 8px rgba(0, 0, 0, 0.14), 0px 1px 14px rgba(0, 0, 0, 0.12)',
    e6: '0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px rgba(0, 0, 0, 0.14), 0px 1px 18px rgba(0, 0, 0, 0.12)',
    e7: '0px 4px 5px -2px rgba(0, 0, 0, 0.2), 0px 7px 10px 1px rgba(0, 0, 0, 0.14), 0px 2px 16px 1px rgba(0, 0, 0, 0.12)',
    e8: '0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12)',
    e9: '0px 5px 6px -3px rgba(0, 0, 0, 0.2), 0px 9px 12px 1px rgba(0, 0, 0, 0.14), 0px 3px 16px 2px rgba(0, 0, 0, 0.12)',
    e10: '0px 6px 6px -3px rgba(0, 0, 0, 0.2), 0px 10px 14px 1px rgba(0, 0, 0, 0.14), 0px 4px 18px 3px rgba(0, 0, 0, 0.12)',
    e11: '0px 6px 7px -4px rgba(0, 0, 0, 0.2), 0px 11px 15px 1px rgba(0, 0, 0, 0.14), 0px 4px 20px 3px rgba(0, 0, 0, 0.12)',
    e12: '0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12)',
    e13: '0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 13px 19px 2px rgba(0, 0, 0, 0.14), 0px 5px 24px 4px rgba(0, 0, 0, 0.12)',
    e14: '0px 7px 9px -4px rgba(0, 0, 0, 0.2), 0px 14px 21px 2px rgba(0, 0, 0, 0.14), 0px 5px 26px 4px rgba(0, 0, 0, 0.12)',
    e15: '0px 8px 9px -5px rgba(0, 0, 0, 0.2), 0px 15px 22px 2px rgba(0, 0, 0, 0.14), 0px 6px 28px 5px rgba(0, 0, 0, 0.12)',
    e16: '0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12)',
    e17: '0px 8px 11px -5px rgba(0, 0, 0, 0.2), 0px 17px 26px 2px rgba(0, 0, 0, 0.14), 0px 6px 32px 5px rgba(0, 0, 0, 0.12)',
    e18: '0px 9px 11px -5px rgba(0, 0, 0, 0.2), 0px 18px 28px 2px rgba(0, 0, 0, 0.14), 0px 7px 34px 6px rgba(0, 0, 0, 0.12)',
    e19: '0px 9px 12px -6px rgba(0, 0, 0, 0.2), 0px 19px 29px 2px rgba(0, 0, 0, 0.14), 0px 7px 36px 6px rgba(0, 0, 0, 0.12)',
    e20: '0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 20px 31px 3px rgba(0, 0, 0, 0.14), 0px 8px 38px 7px rgba(0, 0, 0, 0.12)',
    e21: '0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 21px 33px 3px rgba(0, 0, 0, 0.14), 0px 8px 40px 7px rgba(0, 0, 0, 0.12)',
    e22: '0px 10px 14px -6px rgba(0, 0, 0, 0.2), 0px 22px 35px 3px rgba(0, 0, 0, 0.14), 0px 8px 42px 7px rgba(0, 0, 0, 0.12)',
    e23: '0px 11px 14px -7px rgba(0, 0, 0, 0.2), 0px 23px 36px 3px rgba(0, 0, 0, 0.14), 0px 9px 44px 8px rgba(0, 0, 0, 0.12)',
    e24: '0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12)',
  },
  risk: {
    catastrophic: '#DA291C',
    major: '#ED8B00',
    moderate: '#FFCD00',
    minor: '#62B5E5',
    insignificant: '#C4C4C4',
  },
};

const lightTheme = createTheme({
  overrides: {},
  palette: lightPalette,
  common,
  typography: customTypography,
  breakpoints: customBreakpoints,
});

export default lightTheme;
