import React from 'react';
import { ThemeProvider } from '@mui/material/styles';
import ReactQueryProvider from './context/react-query.provider';
import { ToastProvider } from './context/toastContext';
import { theme } from '@smf/ui-util-app';
import { BrowserRouter } from 'react-router-dom';
import MDUConnectivityContainer from './containers/MDUConnectivityContainer';

// eslint-disable-next-line no-unused-vars
export default function Root(props) {
  return (
    <ThemeProvider theme={theme}>
      <BrowserRouter>
        <ReactQueryProvider>
          <ToastProvider>
            <MDUConnectivityContainer />
          </ToastProvider>
        </ReactQueryProvider>
      </BrowserRouter>
    </ThemeProvider>
  );
}
