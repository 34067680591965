/* eslint-disable react/prop-types */
import React from 'react';
import {
  Grid,
  Box,
  styled,
  Modal,
  Typography,
  MenuItem,
  Select,
  InputLabel,
  FormControl,
} from '@mui/material';

import GeneralButton from '../GeneralButton/index';
import closeIcon from '../../assets/img/closeIcon.svg';
import GeneralTextField from '../GeneralTextField';

import {
  BUTTON_LABELS,
  BUTTON_TYPE,
  CONNECTIVITY_MODALS_CONSTANTS,
} from '../../constants';

import { checkNewGatewayInputError } from '../../utils/helpers';

const PopupModal = styled(Modal)(() => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));

const PopupBox = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
  borderRadius: '.25rem',
  width: '37.5rem',
  minHeight: '22.5rem',
  boxShadow:
    '1px 1px 5px 2px rgba(0, 0, 0, 0.05), 0px 2px 2px rgba(0, 0, 0, 0.14), 0px 1px 5px rgba(0, 0, 0, 0.12)',
}));

const TitleGrid = styled(Grid)(() => ({
  padding: '.875rem 1rem 0.5rem',
}));

const DividerLine = styled('div')(() => ({
  height: '0rem',
  width: '100%',
  border: '.0625rem solid #757575',
}));

const TitleTypography = styled(Typography)(({ theme }) => ({
  fontFamily: 'Inter',
  fontStyle: 'normal',
  color: theme.palette.text.primary,
  fontWeight: 400,
  fontSize: '1rem',
  lineHeight: '1.1875rem',
}));

const ContentGrid = styled(Grid)(() => ({}));

const StyledSelect = styled(Select)(({ theme }) => ({
  padding: '.9375rem .75rem .9375rem 0rem',
  maxHeight: '3.375rem',

  '& .MuiSvgIcon-root.MuiSelect-icon': {
    fill: theme.palette.text.primary,
  },
  '&.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline': {
    border: '.0625em solid rgba(255, 255, 255, 0.23)',
  },

  '&.Mui-focused fieldset': {
    '&.MuiOutlinedInput-notchedOutline': {
      border: '.0625em solid rgba(255, 255, 255, 0.23)',
      outline: 0,
    },
  },
  '& .MuiInputBase-input': {
    width: '100%',
    fontSize: '1rem',
    fontWeight: '400',
    lineHeight: '1.5rem',
    color: theme.palette.text.primary,
    outline: 0,
    '&:focus': {
      border: 'none',
      outline: 0,
    },
    '&:hover': {
      border: 'none',
    },
  },
}));

const ButtonContainerGrid = styled(Grid)(() => ({}));

const GatewayPopupModal = ({
  isEdit = false,
  setIsEdit,
  isModalOpen,
  setModalOpenStatus = () => {},
  selectedDropdown,
  setSelectedDropdown,
  setName,
  setDescription,
  sourceList,
  name,
  description,
  gatewayDetails,
  gatewayAddConfig = () => {},
  gatewayEditConfig = () => {},
  buttonClicked,
  setButtonClicked,
  selectedRowForActions,
  setSelectedRowForActions,
}) => {
  const closeModalAndClearStates = () => {
    setModalOpenStatus(false);
    setButtonClicked(false);
    setSelectedDropdown('');
    setName('');
    setDescription('');
    setIsEdit(false);
    setSelectedRowForActions({
      row: null,
      actionType: '',
    });
  };

  const handleNameChange = (e) => setName(e.target.value);

  const handleDescChange = (e) => setDescription(e.target.value);

  const handleDropdownChange = (e) => setSelectedDropdown(e.target.value);

  // ? NOTE: code to update table gateway data and set modalOpen false
  const handleSave = () => {
    setButtonClicked(true);
    if (!!name && !!description && !!selectedDropdown) {
      const params = {
        action: 'createGateway',
        gatewayName: name,
        gatewayDescription: description,
        gatewaySource: selectedDropdown,
      };
      if (isEdit) {
        gatewayEditConfig(params);
      } else {
        gatewayAddConfig(params);
      }
    }
  };

  return (
    <PopupModal
      open={isModalOpen}
      onClose={() => {
        closeModalAndClearStates();
      }}
      data-testid="popup-modal"
    >
      <PopupBox>
        <TitleGrid
          container
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Grid item>
            <TitleTypography data-testid="popup-modal-title">
              {CONNECTIVITY_MODALS_CONSTANTS.GATEWAY_DETAILS}
            </TitleTypography>
          </Grid>
          <Grid
            data-testid="popup-modal-icon-grid"
            item
            sx={{ cursor: 'pointer' }}
            onClick={() => closeModalAndClearStates()}
          >
            <img src={closeIcon} alt="close-icon" />
          </Grid>
        </TitleGrid>
        <DividerLine />
        <ContentGrid container>
          <Grid item container direction="column" sx={{ padding: '1rem' }}>
            <Grid item sx={{ marginBottom: '1.5rem' }}>
              <GeneralTextField
                fullWidth
                key="name"
                data-testid="name"
                variant="outlined"
                label="Name"
                value={name}
                placeholder="Name"
                onInputChange={handleNameChange}
                errortext={checkNewGatewayInputError(
                  'Name',
                  name,
                  buttonClicked,
                  gatewayDetails,
                  selectedRowForActions
                )}
              />
            </Grid>
            <Grid item sx={{ marginBottom: '1.5rem' }}>
              <GeneralTextField
                key="desc"
                data-testid="desc"
                variant="outlined"
                fullWidth
                label="Description"
                onInputChange={handleDescChange}
                value={description}
                placeholder="Description"
                errortext={checkNewGatewayInputError(
                  'Description',
                  description,
                  buttonClicked
                )}
                //multiline
              />
            </Grid>
            <Grid item sx={{ marginBottom: '1.5rem' }}>
              <FormControl fullWidth sx={{ height: '3.5rem' }}>
                <InputLabel
                  sx={() => ({
                    color: 'rgba(255, 255, 255, 0.6)',
                    fontWeight: '400',
                    lineHeight: '2rem',
                    fontSize: '1rem',
                    top: '-0.375rem',
                    '&.MuiFormLabel-root.MuiInputLabel-root.Mui-focused ': {
                      color: 'rgba(255, 255, 255, 0.6)',
                    },
                  })}
                >
                  Source
                </InputLabel>
                <StyledSelect
                  sx={{
                    pointerEvents: isEdit ? 'none' : 'inherit',
                    '& .MuiSelect-select.MuiSelect-outlined.MuiInputBase-input.MuiOutlinedInput-input':
                      {
                        color: isEdit ? 'rgba(255, 255, 255, 0.6)' : '#fff',
                      },
                  }}
                  label="Source"
                  data-testid="select"
                  onChange={handleDropdownChange}
                  value={selectedDropdown}
                >
                  {sourceList?.map((source) => (
                    <MenuItem
                      id={source}
                      key={source}
                      value={source}
                      disabled={source === selectedDropdown}
                    >
                      {source}
                    </MenuItem>
                  ))}
                </StyledSelect>
                {!!checkNewGatewayInputError(
                  'Source',
                  selectedDropdown,
                  buttonClicked
                ) && (
                  <Grid container direction="row">
                    <Typography
                      sx={{
                        fontSize: '.75rem',
                        color: 'red',
                        padding: '1rem .3125rem 0rem 0rem',
                        marginTop: '.1875rem',
                      }}
                    >
                      {CONNECTIVITY_MODALS_CONSTANTS.ASTERIKS}
                    </Typography>
                    <Typography
                      sx={{
                        fontSize: '.75rem',
                        color: '#FFF',
                        padding: '1rem 0rem 0rem',
                      }}
                    >
                      {checkNewGatewayInputError(
                        'Source',
                        selectedDropdown,
                        buttonClicked
                      )}
                    </Typography>
                  </Grid>
                )}
              </FormControl>
            </Grid>
          </Grid>
        </ContentGrid>

        <ButtonContainerGrid
          container
          direction="row"
          justifyContent="flex-end"
          alignItems="center"
          sx={{ padding: '.1875rem 1rem .8125rem' }}
        >
          <Grid item sx={{ marginRight: '1.5rem' }}>
            <GeneralButton
              data-testid="popup-modal-cancel-button"
              variant="outlined"
              onClick={() => {
                closeModalAndClearStates();
              }}
              text={BUTTON_LABELS.CANCEL}
              type={BUTTON_TYPE.SECONDARY}
              disable={false}
              hover={false}
              height="2.625rem"
              border={true}
              width="5.9375rem"
              borderRadius=".25rem"
              fontSize=".9375rem"
              fontWeight={600}
              lineHeight="1.625rem"
            />
          </Grid>
          <Grid item>
            <GeneralButton
              data-testid="popup-modal-save-button"
              onClick={handleSave}
              text={BUTTON_LABELS.SAVE}
              type={BUTTON_TYPE.PRIMARY}
              disable={
                checkNewGatewayInputError(
                  'Description',
                  description,
                  buttonClicked
                ) ||
                checkNewGatewayInputError(
                  'Name',
                  name,
                  buttonClicked,
                  gatewayDetails,
                  selectedRowForActions
                ) ||
                checkNewGatewayInputError(
                  'Source',
                  selectedDropdown,
                  buttonClicked
                )
              }
              hover={false}
              border={false}
              height="2.625rem"
              width="4.9375rem"
              fontSize=".9375rem"
              fontWeight={600}
              borderRadius=".25rem"
              lineHeight="1.625rem"
            />
          </Grid>
        </ButtonContainerGrid>
      </PopupBox>
    </PopupModal>
  );
};

export default GatewayPopupModal;
