/* eslint-disable react/prop-types */
import React from 'react';
import { Grid, Box, styled, Modal, Typography } from '@mui/material';

import GeneralButton from '../GeneralButton/index';
import closeIcon from '../../assets/img/closeIcon.svg';

import {
  BUTTON_LABELS,
  BUTTON_TYPE,
  CONNECTIVITY_MODALS_CONSTANTS,
  MDU_CONNECTIVITY_CONSTANTS,
} from '../../constants';

const ConfirmModal = styled(Modal)(() => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));

const ConfirmModalBox = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
  borderRadius: '.25rem',
  width: '37.5rem',
  minHeight: '13rem',
  boxShadow:
    '1px 1px 5px 2px rgba(0, 0, 0, 0.05), 0px 2px 2px rgba(0, 0, 0, 0.14), 0px 1px 5px rgba(0, 0, 0, 0.12)',
}));

const ConfirmTitleGrid = styled(Grid)(() => ({
  padding: '.875rem 1rem 0.5rem',
}));

const ConfirmTitleTypography = styled(Typography)(({ theme }) => ({
  fontFamily: 'Inter',
  fontStyle: 'normal',
  color: theme.palette.text.primary,
  fontWeight: 400,
  fontSize: '1rem',
  lineHeight: '1.1875rem',
}));

const ConfirmMessageTypography = styled(Typography)(({ theme }) => ({
  fontFamily: 'Open Sans',
  fontStyle: 'normal',
  color: theme.palette.text.primary,
  fontWeight: 400,
  fontSize: '1rem',
  lineHeight: '1.375rem',
}));

const ConfirmModalDividerLine = styled('div')(() => ({
  height: '0rem',
  width: '100%',
  border: '.0625rem solid #757575',
}));

const ContentGrid = styled(Grid)(() => ({}));

const ButtonContainerGrid = styled(Grid)(() => ({}));

const ConfirmationPopupModal = ({
  actionType = '',
  selectedRow = {},
  isConfirmModalOpen,
  setConfirmModalOpenStatus = () => {},
  onSuccessCallBack = () => {},
  onErrorCallBack = () => {},
}) => {
  // ? code to trigger mutations for enable/disable/delete gateway
  const handleSave = () => {
    setConfirmModalOpenStatus(false);
    onSuccessCallBack(selectedRow, actionType);
  };

  // ? to reset the table data and close the modal
  const closeConfirmationModal = () => {
    onErrorCallBack();
    setConfirmModalOpenStatus(false);
  };

  const setWarningMessage = () => {
    switch (actionType) {
      case MDU_CONNECTIVITY_CONSTANTS.ACTIONS.ENABLE_DISABLE:
        return selectedRow.gatewayStatus
          ? CONNECTIVITY_MODALS_CONSTANTS.CONFIRMATION_MSG_ENABLE
          : CONNECTIVITY_MODALS_CONSTANTS.CONFIRMATION_MSG_DISABLE;

      case MDU_CONNECTIVITY_CONSTANTS.ACTIONS.DELETE:
        return CONNECTIVITY_MODALS_CONSTANTS.CONFIRMATION_MSG_DELETE;

      default:
        break;
    }
  };

  return (
    <ConfirmModal
      open={isConfirmModalOpen}
      onClose={() => {
        closeConfirmationModal();
      }}
      data-testid="popup-modal"
    >
      <ConfirmModalBox>
        <ConfirmTitleGrid
          container
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Grid item>
            <ConfirmTitleTypography data-testid="popup-modal-title">
              {CONNECTIVITY_MODALS_CONSTANTS.CONFIRM}
            </ConfirmTitleTypography>
          </Grid>
          <Grid
            data-testid="popup-modal-icon-grid"
            item
            sx={{ cursor: 'pointer' }}
            onClick={() => {
              closeConfirmationModal();
            }}
          >
            <img src={closeIcon} alt="close icon" />
          </Grid>
        </ConfirmTitleGrid>
        <ConfirmModalDividerLine />
        <ContentGrid container>
          {
            <Grid
              item
              sx={{ padding: '2.125rem 1.5rem 1rem', cursor: 'default' }}
            >
              <ConfirmMessageTypography>
                {setWarningMessage()}
              </ConfirmMessageTypography>
            </Grid>
          }
        </ContentGrid>

        <ButtonContainerGrid
          container
          direction="row"
          justifyContent="flex-end"
          alignItems="center"
          sx={{ padding: '1.5rem' }}
        >
          <Grid item sx={{ marginRight: '1.5rem' }}>
            <GeneralButton
              variant="outlined"
              //Size="large"
              data-testid="popup-modal-cancel-button"
              onClick={() => {
                closeConfirmationModal();
              }}
              text={BUTTON_LABELS.NO}
              type={BUTTON_TYPE.SECONDARY}
              disable={false}
              hover={false}
              border={true}
              borderRadius=".25rem"
              width={'4.125rem'}
              height="2.625rem"
              fontWeight={600}
              fontSize=".9375rem"
              lineHeight="1.625rem"
            />
          </Grid>
          <Grid item>
            <GeneralButton
              data-testid="popup-modal-save-button"
              onClick={handleSave}
              type={BUTTON_TYPE.PRIMARY}
              text={BUTTON_LABELS.YES}
              disable={false}
              hover={false}
              border={false}
              fontWeight={600}
              fontSize=".9375rem"
              lineHeight="1.625rem"
              borderRadius=".25rem"
              width={'4.375rem'}
              height="2.625rem"
            />
          </Grid>
        </ButtonContainerGrid>
      </ConfirmModalBox>
    </ConfirmModal>
  );
};

export default ConfirmationPopupModal;
