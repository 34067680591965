import React from 'react';
import PropTypes from 'prop-types';
import { ThemeProvider } from '@mui/material';
import lightTheme from './theme/theme-light';
import darkTheme from './theme/theme-dark';

function ThemeWrapper({ isDark, children }) {
  if (isDark !== null)
    return (
      <ThemeProvider theme={isDark ? darkTheme : lightTheme}>
        {children}
      </ThemeProvider>
    );
  return children;
}

ThemeWrapper.propTypes = {
  isDark: PropTypes.bool,
  children: PropTypes.node,
};

export default ThemeWrapper;
