export const CONSTRAINT_D2D_CONSTANTS = {
  CONSTRAINT_MANAGEMENT_TITLE: 'Constraint Management',
  CONSTRAINT_STATUS: 'Constraint Status',
  OPEN_CONSTRAINTS: 'Open Constraints',
  SNOOZED_CONSTRAINTS: 'Snoozed Constraints',
  CLOSED_CONSTRAINTS: 'Closed Constraints',
  CONSTRAINT_DURATION_METRICS: 'Constraint Duration Metrics',
  HOUR: 'hr',
  MINS: 'mins',
  ZERO: '0',
  NA: 'N/A',
  CONFIRM: 'Confirm',
  CORRECTIVE_ACTIONS: 'Corrective Actions',
  WORK_ORDER_CREATED: 'Work Order Created',
  ACTIONS: 'Actions',
  START_TIME_OPEN_OR_SNOOZED: 'Planned Start Time',
  START_TIME_CLOSED: 'Maint. Start Time',
  END_TIME_OPEN_OR_SNOOZED: 'Planned End Time',
  END_TIME_CLOSED: 'Maint. End Time',
  PRIORITY: 'Priority',
  TIME_STAMP: 'Time Stamp',
  CONSTRAINT_TYPE: 'Constraint Type',
  LOADING_DATA: 'Loading data',
  INITIAL_LOADING_MESSAGE: 'Loading table data...',
  NO_DATA_AVAILABLE: 'No Data Available',
  NO_SHIFT: 'No shift is currently working',
  SECONDARY_TAB_HEADER: 'Manufacturing Equipment',
  CONSTRAINT_DURATION: 'Constraint Duration',
  AREAS: 'Areas',
  LINES: 'Lines',
  ZONES: 'Zones',
  CELLS: 'Cells',
  ASSETS: 'Assets',
  AREA: 'Area',
  LINE: 'Line',
  ZONE: 'Zone',
  CELL: 'Cell',
  ASSET: 'Asset',
  PARAMETER_TYPE: 'Parameter Type',
  PARAMETER: 'Parameter',
  PART_NAME: 'Part Name',
  PART_NUMBER: 'Part No',
  UOM: 'UoM',
  RECIPE: 'Recipe',
  SETPOINT_LL: 'LL',
  SETPOINT_TARGET: 'Target',
  SETPOINT_UL: 'UL',
  SETPOINT_VALUE: 'SV',
  PRESENT_VALUE: 'PV',

  ROWS_PER_PAGE: 'Rows per page',
  PARAMETER_ENTITY_ID: 'parameterEntityId',
  PARAMETER_TYPE_ENTITY_ID: 'parameterTypeEntityId',
  ASSET_ENTITY_ID: 'assetEntityId',
  CELL_ENITY_ID: 'cellEntityId',
  ZONE_ENTITY_ID: 'zoneEntityId',
  LINE_ENTITY_ID: 'lineEntityId',
  AREA_ENTITY_ID: 'areaEntityId',

  HEADER_CARDS: {
    REFRESH_RATE: 'Refresh Rate',
    TIME_RANGE: 'Time Range',
    PLANT_TIME: 'Plant Time',
  },
  REFRESH_RATE_UNITS: {
    MINUTES: 'min',
    HOURS: 'hours',
    SECONDS: 'seconds',
  },
  CARD_HEADERS: {
    CONSTRINT_STATUS: ' Constraint Status',
    CONSTRINT_DURATION_MERRIX: 'Constraint Duration Metrics',
    MANUFACTURING_EQUIPMENT: 'Manufacturing Equipment',
  },
  CARD_LABELS: {
    TOTAL_DURATION: 'Total Duration',
    AVG_RESOLUTION_TIME: 'Average Resolution Time',
    FORECAST_LABOR_RESOLUTION: 'Forecasted Labor To Resolution',

    OPEN_CONSTRAINTS: 'Open Constraints',
    CLOSED_CONSTRAINTS: 'Closed Constraints',
    SNOOZE_CONSTRAINTS: 'Snoozed Constraints',
  },
  CARD_CONSTRAINTS_UNITS: {
    HOUR: 'hr',
    HOURS: 'hrs',
    MIN: 'min',
    MINS: 'mins',
  },
  DROPDOWN_CONSTANTS: {
    DEFAULT_VALUE: 'SELECT',
  },
  PAGINATION_ROW_COUNT_LIST: [10, 20, 30, 40, 50],
  FAULT_TAB_ITEMS: [
    {
      label: 'View Equipment Fault',
      value: 0,
      disabled: false,
    },
  ],
};

export const TOAST_TYPE = {
  SUCCESS: 'SUCCESS',
  ERROR: 'ERROR',
  WARNING: 'WARNING',
  INFO: 'INFO',
};

export const TOAST_REDUCER_CONSTANTS = {
  SHOW_SUCCESS_TOAST: 'SHOW_SUCCESS_TOAST',
  SHOW_ERROR_TOAST: 'SHOW_ERROR_TOAST',
  SHOW_WARNING_TOAST: 'SHOW_WARNING_TOAST',
  SHOW_INFO_TOAST: 'SHOW_INFO_TOAST',
  HIDE_TOAST: 'HIDE_TOAST',
};

export const DROPDOWN_CONSTANTS = { DEFAULT_VALUE: 'SELECT' };

export const BUTTON_TYPE = {
  PRIMARY: 'primary',
  SECONDARY: 'secondary',
};
export const BUTTON_SIZE = {
  STANDARD: 'standard',
  SMALL: 'small',
};

export const BUTTON_LABELS = {
  SAVE: 'Save',
  NO: 'No',
  YES: 'Yes',
  CANCEL: 'Cancel',
};

export const VIEW_EQUIPMENT_FAULT_CONSTANTS = {
  LINE: 'Line :',
  CELL: 'Cell :',

  TITLES: {
    FAULT_CODE: 'Fault Code',
    FAULT_DESC: 'Fault Description',
    FAULTED_ASSETS: 'Faulted Asset',
    FAULTED_SUB_ASSETS: 'Faulted Sub-Asset',
    FAULTED_COMPO: 'Faulted Component',
    FAULTED_SUB_COMPO: 'Faulted Sub-Component',
  },
};

export const REOPEN_MODAL_CONSTANTS = {
  CONFIRM: 'Confirm!',
  REOPEN_WARNING_MSG:
    'Re-opening constraint will move the snoozed contsraints to Open Constarints.',
  REOPEN_CONFIRMATION_MSG: 'Do you wish to continue?',
};

export const SNOOZE_MODAL_CONSTRAINTS = {
  CONFIRM: 'Confirm!',
  SNOOZE_DISPLAY_MSG:
    'You are about to snooze this constraint, which will remove the constraint from the "Open Constraints" tab for the selected Snooze Time.',
  SNOOZE_CONFIRMATION_MSG: 'Do you wish to snooze this constraint?',
  SNOOZE_SELECT_TEXT: 'Select Snooze Time',
  SNOOZE_NOTE: ' Note:',
  SNOOZE_NOTE_TEXT: 'Snooze Time should be less than',
  SNOOZE_INPUT_ERROR_MSG:
    'The Snooze Time entered for the constraint is more than the Maximum Snooze Time permitted for this constraint',
};

export const TIME_UNITS = {
  HOUR: 'hr',
  MIN: 'min',
};

export const CONSTANT_MESSAGES = {
  POST_SUCCESS_SNOOZE_CONSTRAINTS: 'Constraint has been succesfully snoozed',
  POST_SUCCESS_REOPEN_SNOOZE_CONSTRAINTS:
    'You have successfully Re-opened Constraint',
  DEFAULT_POST_API_ERROR_MESSAGE: 'Could not update the settings',
  NOTES_POST_API_SUCCESS_MESSAGE: 'You have successfully saved notes',
  NOTES_POST_API_ERROR_MESSAGE: 'Could not update the notes',
  WORK_ORDER_POST_API_ERROR_MESSAGE: 'Could not create the work order',
};
export const WORK_ORDER_LIST_CONSTANTS = {
  WORK_ORDER: 'Work Order',
  WO_TYPE: 'Type',
  WO_STATUS: 'Status',
  WO_COMPLETITION: 'Completion',
  WO_RESULTS: 'results',
};
export const WORK_ORDER_DETAILS_CONSTANTS = {
  TITLE: 'Work Order Details',
  WORK_ORDER: 'Work Order',
  DEPARMENT: 'Department',
  ASSIGN_TO: 'Assigned To',
  EQUIPMENT_CODE: 'Equipment Code (#)',
  TRADE: 'Trade',
  WORK_ORDER_TYPE: 'Work Order Type',
  WORK_ORDER_DESCRIPTION: 'Work Order Description',
  ACTIVITIES: 'Activities',
  BREADCRUMB_ITEMS: {
    EQUIPMENT_MANAGEMENT: 'Equipment Management',
    WORK_ORDERS: 'Work Orders',
  },
};

export const RBAC_CONSTANTS = {
  PERMISSIONS: {
    VIEW_ONLY: 'View Only',
    EDIT_ONLY: 'Edit Only',
    EDIT_AND_CONFIGURE: 'Edit and Configure',
    SYSTEM_ADMIN: 'System Admin',
  },
};

export const CONFIG_WARNING_CONSTANTS = {
  CONFIG_ROLE_CONSTANTS: {
    WARNING_TEXT_MSG:
      'Equipment Constraints Configuration has not been completed yet',
    SECONDARY_WARNING_TEXT_MSG: 'Please finish configuration to see Data',
  },
  NON_CONFIG_ROLE_CONSTANTS: {
    WARNING_TEXT_MSG:
      'Equipment Constraints Configuration has not been completed yet',
    SECONDARY_WARNING_TEXT_MSG: 'Please contact your system administrator.',
  },
};

export const MDU_CONNECTIVITY_CONSTANTS = {
  ACTIONS: {
    REFRESH_CERTIFICATE: 'Refresh certificate',
    EDIT: 'Edit',
    DELETE: 'Delete',
    ENABLE_DISABLE: 'enable/disable',
  },
};

export const CONNECTIVITY_MODALS_CONSTANTS = {
  CONNECTIVITY: 'Connectivity',
  ASTERIKS: '*',
  CONFIRM: 'Confirm',
  CONFIRMATION_MSG_DISABLE:
    'You are about to disable a gateway. Would you like to continue?',
  CONFIRMATION_MSG_ENABLE:
    'You are about to enable a gateway. Would you like to continue?',
  CONFIRMATION_MSG_DELETE:
    'You are about to delete a gateway. Would you like to continue?',
  GATEWAY_DETAILS: 'Gateway Details',
  FIELD_NAME: 'Name',
};
