import React from 'react';
import PropTypes from 'prop-types';
import {
  useReactTable,
  getCoreRowModel,
  flexRender,
} from '@tanstack/react-table';
import {
  Box,
  Divider,
  Paper,
  Skeleton,
  styled,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Grid,
} from '@mui/material';

import { getInitialConfig, globalFilterFn } from './tableUtils';
import MESSAGE_STRINGS, { CONSTANT_MESSAGES } from '../../constants/en-us';
import TableSearchField from './table-components/TableSearchField';
import HeaderOptionsMenu from './table-components/HeaderOptionsMenu';
import HeaderSortArrow from './table-components/HeaderSortArrow';
import CustomFooterComponent from './table-components/CustomFooterComponent';
import ThemeWrapper from '../../utils/ThemeWrapper';
import { isAlphanumeric, isEmptyString } from '../../utils/helpers';
import ErrorBox from '../ErrorBox';

const HeaderCell = styled(TableCell)(() => ({
  background: '#000000',
  padding: '.5rem',
  paddingLeft: '0.5rem',
  color: 'rgba(255, 255, 255, 0.87)',
}));

const TablePaper = styled(Paper)(() => ({
  width: '100%',
  overflow: 'hidden',
  background: '#000000',
  boxShadow: 'none',
}));
const TableBodyRow = styled(TableRow)(() => ({
  height: '3rem',
}));
const TableBodyCell = styled(TableCell)(() => ({
  padding: '0 .5rem',
  borderBottom: 'none',
  color: 'rgba(255, 255, 255, 0.87)',
  paddingLeft: '0.5rem',
}));

const SMFTable = ({
  rows = [],
  columns = [],
  stickyHeader = true,
  maxHeight = '100%',
  minHeight = '100%',
  height = '100%',
  initialPagination = { pageIndex: 0, pageSize: 5 },
  tableName = 'smf-table',
  loading = false,
  tableConfig = {},
  customGlobalFilterFn = globalFilterFn,
  EmptySearchTableLabel = () => 'No search result found',
  FooterComponent = (props) => <CustomFooterComponent {...props} />,
  isDark = false,
  TableTopRightComponent = () => null,
}) => {
  const tableData = React.useMemo(
    () => (loading ? Array(5).fill({}) : rows),
    [loading, rows]
  );

  const [globalFilter, setGlobalFilter] = React.useState('');

  const isInvalidSearchString = () => {
    // ? if string is empty, no need to check, return false
    if (isEmptyString(globalFilter)) {
      return false;
    }

    // ? else check if string is alphanumeric and if not, return true to display error
    return !isAlphanumeric(globalFilter);
  };

  const [sorting, setSorting] = React.useState([]);
  const tableColumns = React.useMemo(
    () =>
      loading
        ? columns.map((column) => ({
            ...column,
            cell: (
              <Skeleton
                height={42}
                className="smf-table-loader"
                data-testid="smf-table-skeleton"
              />
            ),
          }))
        : columns,
    [loading, columns]
  );

  const { configObj, initialConfig } = getInitialConfig(tableConfig);

  const instance = useReactTable({
    data: tableData,
    columns: tableColumns,
    initialState: {
      pagination: initialPagination,
    },
    state: { globalFilter, sorting },
    getCoreRowModel: getCoreRowModel(),
    globalFilterFn: customGlobalFilterFn,
    onSortingChange: setSorting,
    onGlobalFilterChange: setGlobalFilter,
    ...initialConfig,
  });
  const isEmptyLabelDisplayed =
    instance.getRowModel().rows.length === 0 &&
    (Boolean(EmptySearchTableLabel) || Boolean(configObj?.emptyRowsText));

  return (
    <ThemeWrapper isDark={isDark}>
      <TablePaper
        className="smf-table-paper"
        data-testid={`${tableName}-paper`}
      >
        <Grid
          container
          className="smf-table-top-container"
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          {configObj.search && (
            <Box>
              <TableSearchField
                value={globalFilter}
                onChange={(value) => setGlobalFilter(String(value))}
                placeholder={
                  configObj?.searchPlaceholder ||
                  MESSAGE_STRINGS['SMFToast.placeholder.searchField']
                }
                data-testid={`${tableName}-searchfield`}
                className="smf-table-searchfield"
                sx={{
                  height: '3.25rem',
                  width: '16.25rem',
                  '& .tableSearchIcon': {
                    path: {
                      fill: (theme) => theme.palette.text.primary,
                    },
                  },
                }}
                inputProps={{ maxLength: 255 }}
              />
              {isInvalidSearchString() && (
                <ErrorBox
                  errorMessage={CONSTANT_MESSAGES.MDU.INVALID_SEARCH_TEXT}
                />
              )}
            </Box>
          )}
          <Box>
            <TableTopRightComponent />
          </Box>
        </Grid>
        <TableContainer
          sx={{ maxHeight, height, minHeight }}
          className="smf-table-container"
          data-testid={`${tableName}-container`}
        >
          <Table
            stickyHeader={stickyHeader}
            aria-label="table"
            className="smf-table"
            sx={{
              height: isEmptyLabelDisplayed ? '100%' : 'auto',
            }}
          >
            {instance.getHeaderGroups().map((headerGroup) => (
              <TableHead
                key={headerGroup.id}
                className="smf-table-header"
                data-testid={`${tableName}-head`}
              >
                <TableRow
                  className="smf-table-header-row"
                  data-testid={`${tableName}-header-row`}
                >
                  {headerGroup.headers.map((header) => (
                    <HeaderCell
                      key={header.id}
                      colSpan={header.colSpan}
                      width={header.column.columnDef.width}
                      sx={{
                        ...header.column.columnDef.sx,
                      }}
                      className="smf-table-header-cell"
                      data-testid={`${tableName}-header-cell`}
                    >
                      {header.isPlaceholder ||
                      header.column.columnDef.isEmptyHeader ? null : (
                        <Box
                          sx={{
                            alignItems: 'center',
                            display: 'flex',
                            flexDirection: 'row',
                            width: '100%',
                          }}
                        >
                          <Box
                            sx={{
                              alignItems: 'center',
                              display: 'flex',
                              flexDirection: 'row',
                              overflow: 'hidden',
                              flexGrow: 1,
                              [`&:hover .changeVisibility${header.id}`]: {
                                visibility: 'visible',
                              },
                              [`.changeOpacity${header.id}`]: {
                                opacity:
                                  header.column.getIsSorted() === false
                                    ? '0.5'
                                    : 1,
                              },
                            }}
                            data-testid={`${tableName}-header-value`}
                          >
                            {flexRender(
                              header.column.columnDef.header,
                              header.getContext()
                            )}
                            {configObj.sorting &&
                              header.column.getCanSort() && (
                                <HeaderSortArrow
                                  sortHandler={header.column.getToggleSortingHandler()}
                                  selectedSort={header.column.getIsSorted()}
                                  id={header.column.id}
                                  data-testid={`${tableName}-sort-icon`}
                                  nextSortingOrder={header.column.getNextSortingOrder()}
                                />
                              )}
                          </Box>
                          {configObj.sorting && header.column.getCanSort() && (
                            <HeaderOptionsMenu
                              id={header.column.id}
                              selectedSort={header.column.getIsSorted()}
                              toggleSorting={header.column.toggleSorting}
                              clearSorting={header.column.clearSorting}
                            />
                          )}
                          <Box>
                            {!header.column.columnDef.hideDivider && (
                              <Divider
                                orientation="vertical"
                                variant="middle"
                                flexItem
                                sx={{
                                  height: '1rem',
                                  margin: 0,
                                  borderWidth: '.0625rem',
                                  background: (theme) =>
                                    theme.palette.grey.grey300,
                                }}
                              />
                            )}
                          </Box>
                        </Box>
                      )}
                    </HeaderCell>
                  ))}
                </TableRow>
              </TableHead>
            ))}
            <TableBody
              className="smf-table-body"
              data-testid={`${tableName}-body`}
              sx={{
                'tr:nth-of-type(odd)': {
                  background: (theme) => theme.palette.grey.grey100,
                },
              }}
            >
              {isEmptyLabelDisplayed ? (
                <TableBodyRow
                  sx={{ '&.smf-table': { height: '100%' } }}
                  data-testid={`${tableName}-empty-row`}
                  className="smf-table-empty-row"
                >
                  <TableBodyCell
                    colSpan={instance.getHeaderGroups()[0].headers.length}
                    align="center"
                  >
                    {rows.length === 0 ? (
                      configObj?.emptyRowsText
                    ) : (
                      <EmptySearchTableLabel text={globalFilter} />
                    )}
                  </TableBodyCell>
                </TableBodyRow>
              ) : (
                instance.getRowModel().rows.map((row) => {
                  return (
                    <TableBodyRow
                      tabIndex={-1}
                      key={row.id}
                      className="smf-table-body-row"
                      data-testid={`${tableName}-body-row`}
                    >
                      {row.getVisibleCells().map((cell) => {
                        return (
                          <TableBodyCell
                            key={cell.id}
                            align={cell.align}
                            className="smf-table-body-cell"
                            data-testid={`${tableName}-${cell.column.id}-body-cell`}
                          >
                            {flexRender(
                              cell.column.columnDef.cell,
                              cell.getContext()
                            )}
                          </TableBodyCell>
                        );
                      })}
                    </TableBodyRow>
                  );
                })
              )}
            </TableBody>
          </Table>
        </TableContainer>
        {configObj.footer && (
          <FooterComponent
            instance={instance}
            data-testid={`${tableName}-footer`}
          />
        )}
      </TablePaper>
    </ThemeWrapper>
  );
};

SMFTable.propTypes = {
  rows: PropTypes.arrayOf(PropTypes.shape({})),
  columns: PropTypes.arrayOf(PropTypes.shape({})),
  height: PropTypes.string,
  minHeight: PropTypes.string,
  maxHeight: PropTypes.string,
  initialPagination: PropTypes.shape({
    pageSize: PropTypes.number,
    pageIndex: PropTypes.number,
  }),
  stickyHeader: PropTypes.bool,
  tableName: PropTypes.string,
  loading: PropTypes.bool,
  tableConfig: PropTypes.shape({}),
  emptyRowsText: PropTypes.string,
  EmptySearchTableLabel: PropTypes.node,
  FooterComponent: PropTypes.node,
  TableTopRightComponent: PropTypes.node,
  isDark: PropTypes.bool,
  customGlobalFilterFn: PropTypes.func,
};

export default SMFTable;
