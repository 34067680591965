import {
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
} from '@tanstack/react-table';

export const getInitialConfig = (obj) => {
  const configObj = {
    sorting: true,
    search: true,
    pagination: false,
    footer: false,
    ...obj,
  };
  let initialConfig = {};
  if (configObj.sorting) {
    initialConfig = {
      ...initialConfig,
      getSortedRowModel: getSortedRowModel(),
    };
  }
  if (configObj.pagination) {
    initialConfig = {
      ...initialConfig,
      autoResetPageIndex: false,
      getPaginationRowModel: getPaginationRowModel(),
    };
  }
  if (configObj.search) {
    initialConfig = {
      ...initialConfig,
      getFilteredRowModel: getFilteredRowModel(),
    };
  }
  return { initialConfig, configObj };
};

export const globalFilterFn = (row, columnId, filterValue) => {
  const search = filterValue?.toLowerCase();

  let value = row.getValue(columnId);
  if (typeof value === 'number') value = String(value);

  return value?.toLowerCase().includes(search);
};

export const globalSearchForMultipleColumns = (
  row,
  columnIdList = [],
  filterValue
) => {
  const search = filterValue?.toLowerCase();

  // ? store the values for each column inside a row as an array. ensure all numbers are converted to string.
  let valuesForEachColumnInARow = columnIdList.map((columnId) => {
    let columnValueForRow = row.original[columnId];
    if (typeof value === 'number') {
      columnValueForRow = String(columnValueForRow).toLowerCase();
    }
    return columnValueForRow.toLowerCase();
  });

  // ? check if any values in the row matches the search text
  const isRowFiltered = valuesForEachColumnInARow.some((value) => {
    return value.toLowerCase().includes(search);
  });

  return isRowFiltered;
};
