import React from 'react';
import PropTypes from 'prop-types';

import { Box, Grid, Typography } from '@mui/material';

import { ReactComponent as ErrorExclamationIcon } from '../../assets/img/errorExclamation.svg';

const ErrorBox = ({ errorMessage, maxWidth = '7.5rem' }) => {
  return errorMessage ? (
    <Grid
      item
      xs={12}
      sx={{ margin: '1rem 0 .5625rem 0' }}
      container
      direction="row"
      justifyContent="flex-start"
      data-testid="error-box"
    >
      <Grid item xs={1}>
        <Box sx={{ margin: '.375rem .4375rem 0 0' }} data-testid="error-icon">
          <ErrorExclamationIcon />
        </Box>
      </Grid>
      <Grid item xs={11} sx={{ maxWidth: maxWidth }}>
        <Typography sx={{ whiteSpace: 'nowrap' }} variant="caption">
          {errorMessage}
        </Typography>
      </Grid>
    </Grid>
  ) : (
    <Grid
      item
      xs={12}
      sx={{ margin: '1rem 0 .5625rem 0' }}
      container
      direction="row"
      justifyContent="flex-start"
      data-testid="error-box"
    >
      <Grid item xs={2}>
        <Box sx={{ margin: '.375rem .4375rem 0 0' }} data-testid="error-icon">
          {/* <ErrorExclamationIcon /> */}
        </Box>
      </Grid>
      <Grid item xs={8} sx={{ width: maxWidth }}>
        <Typography variant="caption" />
      </Grid>
    </Grid>
  );
};

export default ErrorBox;

ErrorBox.propTypes = {
  errorMessage: PropTypes.string,
  maxWidth: PropTypes.string,
  isError: PropTypes.bool,
};
