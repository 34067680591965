import React from 'react';
import { Button as MuiButton, Typography, styled } from '@mui/material';
import PropTypes from 'prop-types';
import { BUTTON_SIZE, BUTTON_TYPE } from '../../constants';

const PrimaryButton = styled(MuiButton, {
  shouldForwardProp: (prop) => prop !== 'type' && prop !== 'buttonSize',
})(
  ({
    theme,
    buttonSize,
    fontWeight,
    borderRadius,
    hover,
    border,
    width,
    height,
  }) => {
    const isStandard = buttonSize === BUTTON_SIZE.STANDARD;
    return {
      margin: '0rem',
      color: `${theme.palette.text.primary} `,
      border: border ? '0.0625rem solid blue' : 'none',
      boxSizing: 'border-box',
      borderRadius: borderRadius ? borderRadius : '1.25rem',
      minWidth: width ? width : '100%',
      width: width ? width : '100%',
      height: height ? height : '100%',
      fontSize: isStandard ? '.875rem' : '.75rem',
      fontWeight: fontWeight ? fontWeight : 400,
      background: '#007CB0',
      boxShadow:
        '1px 1px 5px 2px rgba(0, 0, 0, 0.05), 0px 2px 2px rgba(0, 0, 0, 0.14), 0px 1px 5px rgba(0, 0, 0, 0.12)',

      'MuiButton-startIcon:hover': {
        color: '#171717',
        backgroundColor: 'white',
      },
      '>svg': {
        fill: 'white',
      },

      '&:hover': {
        color: `${theme.palette.text.primary} `,
        background: hover ? '' : '#007CB0',
        '&:hover': {
          '>svg': {
            fill: hover ? '#171717' : '',
          },
        },
      },
      '&:disabled': {
        color: `${theme.customColors.twentySevenJungleGrey} `,
        background: `${theme.palette.background.darkGrey} `,
        border: `0.0625rem solid ${theme.palette.border.lightRaven}`,
        fontWeight: '600',
      },
    };
  }
);

const SecondaryButton = styled(MuiButton)(
  ({
    theme,
    buttonSize,
    fontWeight,
    borderRadius,
    hover,
    border,
    width,
    height,
  }) => {
    const isStandard = buttonSize === BUTTON_SIZE.STANDARD;
    return {
      margin: '0rem',
      color: '#007CB0',
      border: border ? '.0625rem solid rgba(0, 163, 224, 0.5)' : 'none',
      boxSizing: 'border-box',
      borderRadius: borderRadius ? borderRadius : '1.25rem',
      minWidth: width ? width : '100%',
      width: width ? width : '100%',
      height: height ? height : '100%',
      fontWeight: fontWeight ? fontWeight : 400,
      background: 'inherit',
      'MuiButton-startIcon:hover': {
        color: '#171717',
        backgroundColor: 'white',
      },
      '>svg': {
        fill: 'white',
      },
      fontSize: isStandard ? '.875rem' : '.75rem',

      '&:hover': {
        border: border ? '.0625rem solid rgba(0, 163, 224, 0.5)' : 'none',
        color: hover ? theme.palette.text.secondary : '#007CB0',
        background: hover ? theme.palette.text.primary : 'inherit',
        '&:hover': {
          '>svg': {
            fill: '#171717',
          },
        },
      },
      '&:disabled': {
        color: theme.palette.text.darkGrey,
        background: 'transparent',
        border: `0.0625rem solid ${theme.palette.border.darkGrey}`,
      },
    };
  }
);

const ButtonLabel = styled(Typography)((fontWeight, fontSize, lineHeight) => ({
  textTransform: 'none',
  fontWeight: fontWeight ? fontWeight : 400,
  fontSize: fontSize ?? fontSize,
  lineHeight: lineHeight ?? lineHeight,
}));

function GeneralButton({
  type = BUTTON_TYPE.PRIMARY,
  text,
  size,
  color,
  variant,
  onClick,
  disable,
  children,
  fontWeight,
  fontSize,
  lineHeight,
  buttonSize = BUTTON_SIZE.STANDARD,
  borderRadius,
  hover,
  border,
  width,
  height,
  ...other
}) {
  return type === BUTTON_TYPE.SECONDARY ? (
    <SecondaryButton
      variant={variant || 'contained'}
      size={size || 'medium'}
      color={color || 'primary'}
      onClick={onClick}
      disabled={disable || false}
      buttonSize={buttonSize}
      borderRadius={borderRadius}
      hover={hover || false}
      border={border || false}
      width={width}
      height={height}
      data-testid="general-secondary-button"
      {...other}
    >
      {children || (
        <ButtonLabel
          variant="subtitle1"
          fontWeight={fontWeight}
          fontSize={fontSize}
          lineHeight={lineHeight}
        >
          {text}
        </ButtonLabel>
      )}
    </SecondaryButton>
  ) : (
    <PrimaryButton
      variant={variant || 'contained'}
      size={size || 'medium'}
      color={color || 'primary'}
      onClick={onClick}
      disabled={disable || false}
      buttonSize={buttonSize}
      borderRadius={borderRadius}
      hover={hover || false}
      border={border || false}
      width={width}
      height={height}
      data-testid="general-primary-button"
      {...other}
    >
      {children || (
        <ButtonLabel
          variant="subtitle1"
          fontWeight={fontWeight}
          fontSize={fontSize}
          lineHeight={lineHeight}
        >
          {text}
        </ButtonLabel>
      )}
    </PrimaryButton>
  );
}

GeneralButton.propTypes = {
  type: PropTypes.string,
  text: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  size: PropTypes.string,
  color: PropTypes.string,
  variant: PropTypes.string,
  disable: PropTypes.bool,
  fontWeight: PropTypes.number,
  fontSize: PropTypes.number,
  lineHeight: PropTypes.string,
  onClick: PropTypes.func,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  buttonSize: PropTypes.string,
  borderRadius: PropTypes.string,
  hover: PropTypes.bool,
  border: PropTypes.bool,
  width: PropTypes.string,
  height: PropTypes.string,
};

export default GeneralButton;
