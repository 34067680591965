import React from 'react';
import PropTypes from 'prop-types';
import { Box, Typography } from '@mui/material';

function CustomFooterComponent({ instance, ...rest }) {
  return (
    <Box
      sx={{
        display: 'flex',
        padding: '.75rem .75rem 0 .75rem',
        justifyContent: 'flex-end',
      }}
      className="smf-table-footer"
      {...rest}
    >
      <Typography>
        {instance.getRowModel().rows.length} of{' '}
        {instance.getCoreRowModel().rows.length}
      </Typography>
    </Box>
  );
}

CustomFooterComponent.propTypes = {
  instance: PropTypes.shape({
    getRowModel: PropTypes.func,
    getCoreRowModel: PropTypes.func,
  }),
};

export default CustomFooterComponent;
