import { globalstate$ } from '@smf/ui-util-app';

/**
 * Function to Update Global state when user tries to navigate away from FactoryGeneral Page
 * @param {String,Boolean} entityId, woPageOpen string to update workOrderState
 */
export const updateWorkOrdersState = (entityId, woPageOpen, faultcode) => {
  const state = globalstate$.getValue();
  globalstate$.next({
    ...state,
    workOrdersState: {
      ...state.workOrdersState,
      isWOPageOpen: woPageOpen,
      entityID: entityId,
      faultCode: faultcode,
    },
  });
};

export const updateRxjsStateWithConnectivityTabStatus = (
  connectivityStatus
) => {
  const state = globalstate$.getValue();
  globalstate$.next({
    ...state,
    configStatus: { ...state.configStatus, connectivity: connectivityStatus },
  });
};
