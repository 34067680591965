import React, { useEffect, useState } from 'react';
import { IconButton, InputAdornment, TextField } from '@mui/material';
import PropTypes from 'prop-types';

import { ReactComponent as SearchIcon } from '../../../assets/img/SearchIcon.svg';
import { ReactComponent as ClearIcon } from '../../../assets/img/ClearIcon.svg';

export default function TableSearchField({
  value: initialValue,
  onChange,
  debounce = 500,
  ...props
}) {
  const [value, setValue] = useState(initialValue);

  useEffect(() => {
    setValue(initialValue);
  }, [initialValue]);

  useEffect(() => {
    const timeout = setTimeout(() => {
      onChange(value);
    }, debounce);

    return () => clearTimeout(timeout);
  }, [debounce, onChange, value]);

  return (
    <TextField
      {...props}
      variant="standard"
      value={value}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <SearchIcon className="tableSearchIcon" />
          </InputAdornment>
        ),
        endAdornment: (
          <InputAdornment position="end">
            <IconButton onClick={() => onChange('')} sx={{ padding: 0 }}>
              <ClearIcon
                height=".8rem"
                width=".8rem"
                className="tableSearchIcon"
              />
            </IconButton>
          </InputAdornment>
        ),
      }}
      onChange={(e) => setValue(e.target.value)}
    />
  );
}

TableSearchField.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func,
  debounce: PropTypes.number,
};
