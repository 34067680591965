export const customTypography = {
  h1: {
    fontWeight: 300,
    fontSize: '6rem',
  },
  h2: {
    fontWeight: 300,
    fontSize: '3.75rem',
  },
  h3: {
    fontWeight: 400,
    fontSize: '3rem',
  },
  h4: {
    fontWeight: 400,
    fontSize: '2.125rem',
  },
  h5: {
    fontWeight: 400,
    fontSize: '1.5rem',
  },
  h6: {
    fontWeight: 600,
    fontSize: '1.25rem',
  },
  subtitle1: {
    fontWeight: 400,
    fontSize: '1rem',
  },
  subtitle2: {
    fontWeight: 600,
    fontSize: '.875rem',
  },
  body1: {
    fontWeight: 400,
    fontSize: '1rem',
  },
  body2: {
    fontWeight: 400,
    fontSize: '.875rem',
  },
  buttonLarge: {
    fontWeight: 600,
    fontSize: '.9375rem',
  },
  buttonMedium: {
    fontWeight: 600,
    fontSize: '.875rem',
  },
  buttonSmall: {
    fontWeight: 600,
    fontSize: '.8125rem',
  },
  caption: {
    fontWeight: 400,
    fontSize: '.75rem',
  },
  overline: {
    fontWeight: 400,
    fontSize: '.75rem',
  },
  avatarLetter: {
    fontWeight: 400,
    fontSize: '1.25rem',
  },
  inputLabel: {
    fontWeight: 400,
    fontSize: '.75rem',
  },
  helperText: {
    fontWeight: 400,
    fontSize: '.75rem',
  },
  inputText: {
    fontWeight: 400,
    fontSize: '1rem',
  },
  tooltip: {
    fontWeight: 600,
    fontSize: '0.625rem',
  },
  fontFamily: 'Open Sans',
};
