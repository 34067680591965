import React from 'react';
import { IconButton, Menu, MenuItem } from '@mui/material';
import PropTypes from 'prop-types';
import { ReactComponent as Option } from '../../assets/img/MoreVert.svg';
import { MDU_CONNECTIVITY_CONSTANTS } from '../../constants';

const MDUActionsMenu = ({ row, onActionClick }) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <IconButton
        id="table-action-button"
        data-testid="mdu-action-button"
        aria-controls={open ? 'mdu-option-button' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
        className="smf-table-mdu-action"
        sx={{
          '& .header-option-icon': {
            path: {
              fill: 'rgba(255, 255, 255, 0.87)',
            },
          },
        }}
      >
        <Option
          height="1.25rem"
          width="1.25rem"
          className="header-option-icon"
        />
      </IconButton>
      <Menu
        id={'table-action-button'}
        data-testid={'table-option-menu'}
        aria-labelledby="table-action-option-menu"
        className="smf-table-action-option-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        sx={{
          '.MuiMenu-paper': {
            background: (theme) =>
              `${theme.palette.background.paper} !important`,
            color: (theme) => `${theme.palette.text.primary} !important`,
          },
        }}
      >
        <MenuItem
          onClick={() => {
            onActionClick(
              row,
              MDU_CONNECTIVITY_CONSTANTS.ACTIONS.REFRESH_CERTIFICATE
            );
            handleClose();
          }}
          data-testid="menu-option-refresh"
        >
          {MDU_CONNECTIVITY_CONSTANTS.ACTIONS.REFRESH_CERTIFICATE}
        </MenuItem>
        <MenuItem
          onClick={() => {
            onActionClick(row, MDU_CONNECTIVITY_CONSTANTS.ACTIONS.EDIT);
            handleClose();
          }}
          data-testid="menu-option-edit"
        >
          {MDU_CONNECTIVITY_CONSTANTS.ACTIONS.EDIT}
        </MenuItem>
        <MenuItem
          onClick={() => {
            onActionClick(row, MDU_CONNECTIVITY_CONSTANTS.ACTIONS.DELETE);
            handleClose();
          }}
          data-testid="menu-option-delete"
        >
          {MDU_CONNECTIVITY_CONSTANTS.ACTIONS.DELETE}
        </MenuItem>
      </Menu>
    </>
  );
};

export default MDUActionsMenu;

MDUActionsMenu.propTypes = {
  row: PropTypes.shape({}),
  onActionClick: PropTypes.func,
};
