import React, { useState, useEffect } from 'react';
import { Typography, Box, styled } from '@mui/material';
import { useQuery, useMutation, useQueryClient } from 'react-query';

import { MDUTableColumns } from '../../utils/TableColumns/MDUTableColumns';
import SMFTable from '../../components/SmfTable/index';
import GeneralButton from '../../components/GeneralButton';
import AddEditGatewayModal from '../../components/AddEditGatewayModal';
import ConfirmationPopupModal from '../../components/ConfirmationPopupModal';

import {
  gatewayAddConfigWebSocket,
  gatewayDeleteWebSocket,
} from '../../utils/websocket';

import {
  queryConstants,
  retrieveMDUSourceData,
  retrieveMDUGatewayTableData,
  updateMDUGatewayData,
  updateMDUGatewayStatusData,
} from '../../utils/apihelper';

import {
  formatGatewayDatafromAPI,
  closeModalAndClearStates,
  downloadFiles,
  setGatewayTableRows,
  onActionClickHelper,
  createEditParams,
  createEnableDisableParams,
  getCreateGatewayParams,
  getDeleteGatewayParams,
} from '../../utils/helpers';
import {
  BUTTON_TYPE,
  CONNECTIVITY_MODALS_CONSTANTS,
  MDU_CONNECTIVITY_CONSTANTS,
  TOAST_REDUCER_CONSTANTS,
} from '../../constants';
import { CONSTANT_MESSAGES } from '../../constants/en-us';

import { useToastContext } from '../../context/toastContext';
import { useRxjsState } from '../../utils/useRxjsState';
import { globalstate$ } from '@smf/ui-util-app';
import { globalSearchForMultipleColumns } from '../../components/SmfTable/tableUtils';
import { updateRxjsStateWithConnectivityTabStatus } from '../../utils/rxjsStateNext';

const StyledTableBox = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.background.eerieBlack,
  padding: '2.5rem 1.5rem',
  height: '59.5rem',
  '& .smf-table-paper': {
    padding: '.5rem',
  },
  '& .smf-table-top-container': {
    padding: '0 .5rem',
    marginBottom: '0',
  },
}));

export const MDUConnectivityContainer = () => {
  const [gatewayTableData, setGatewayTableData] = useState([]);
  const [isResetTableDataToAPIData, setIsResetTableDataToAPIData] =
    useState(false);
  // ? NOTE: this state will be used for create/edit modals
  const [isModalOpen, setIsModalOpen] = useState(false);

  // ? NOTE: this state will be used for enable/disable/delete modals
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);

  const [isEdit, setIsEdit] = useState(false); // ? NOTE: gateway edit modal will show all prefilled inputs based on this state
  // ? NOTE: for not doing required field validations for add gateway modal initially, because save button should be clickable when opening it for the first time
  const [buttonClicked, setButtonClicked] = useState(false);

  // ? NOTE: this row will be kept for handling mutations from the edit/delete/enable-disable modals
  const [selectedRowForActions, setSelectedRowForActions] = useState({
    row: null,
    actionType: '',
  });

  const [selectedDropdown, setSelectedDropdown] = useState('');
  const [name, setName] = useState('');
  const [description, setDescription] = useState('');

  const { rxjsState } = useRxjsState();
  const queryClient = useQueryClient();
  const { toastDispatch } = useToastContext();

  const authToken = globalstate$.value.sessionToken;

  // ? web socket to create mdu gateway
  const { mutate: gatewayAddConfig } = useMutation(
    [queryConstants.GET_MDU_CONFIG_ADD],
    async () => {
      closeModalAndClearStates(
        setIsModalOpen,
        setButtonClicked,
        setSelectedDropdown,
        setName,
        setDescription,
        setIsEdit,
        setSelectedRowForActions
      );
      const createParams = getCreateGatewayParams(
        rxjsState,
        name,
        description,
        selectedDropdown,
        authToken
      );
      return gatewayAddConfigWebSocket(createParams);
    },

    {
      onError: (error) => {
        if (error.isError) {
          toastDispatch({
            type: TOAST_REDUCER_CONSTANTS.SHOW_ERROR_TOAST,
            payload: {
              message: CONSTANT_MESSAGES.MDU.GATEWAY_NOT_CREATED,
            },
          });
        } else {
          downloadFiles(error?.certUrl, error?.guideDocUrl);
          //? resetting all modal states fields
          toastDispatch({
            type: TOAST_REDUCER_CONSTANTS.SHOW_SUCCESS_TOAST,
            payload: {
              message: CONSTANT_MESSAGES.MDU.GATEWAY_CREATED,
            },
          });

          // ? NOTE: fetch the table api to get the updated data.
          queryClient.invalidateQueries(
            queryConstants.GET_MDU_GATEWAY_TABLE_DATA
          );
        }
      },
      enabled: !!rxjsState.factoryId,
      retry: false,
    }
  );
  // ? web socket to delete mdu gateway
  const { mutate: deleteGatewayMutate } = useMutation(
    [queryConstants.DELETE_MDU_GATEWAY],
    async () => {
      const deleteParams = getDeleteGatewayParams(
        rxjsState,
        selectedRowForActions
      );
      return gatewayDeleteWebSocket(deleteParams);
    },

    {
      onError: (error) => {
        if (error.isError) {
          // ? NOTE: reset the table data back from cached api
          setIsResetTableDataToAPIData(true);
          toastDispatch({
            type: TOAST_REDUCER_CONSTANTS.SHOW_ERROR_TOAST,
            payload: {
              message: CONSTANT_MESSAGES.MDU.GATEWAY_NOT_DELETED,
            },
          });
        } else {
          toastDispatch({
            type: TOAST_REDUCER_CONSTANTS.SHOW_SUCCESS_TOAST,
            payload: {
              message: CONSTANT_MESSAGES.MDU.GATEWAY_DELETED,
            },
          });
          // ? NOTE: fetch the table api to get the updated data.
          queryClient.invalidateQueries(
            queryConstants.GET_MDU_GATEWAY_TABLE_DATA
          );
        }
      },
      enabled: !!rxjsState.factoryId,
      retry: false,
    }
  );

  // ? callback that triggers edit gateway post api
  const gatewayEditConfig = (params) => {
    closeModalAndClearStates(
      setIsModalOpen,
      setButtonClicked,
      setSelectedDropdown,
      setName,
      setDescription,
      setIsEdit,
      setSelectedRowForActions
    );
    let editParams = createEditParams(params, selectedRowForActions, rxjsState);
    gatewayEditMutate(editParams);
  };

  //? gateway post API for updating edited gatway details
  const { mutate: gatewayEditMutate } = useMutation(
    (data) => updateMDUGatewayData(data),
    {
      onSuccess: () => {
        toastDispatch({
          type: TOAST_REDUCER_CONSTANTS.SHOW_SUCCESS_TOAST,
          payload: {
            message: CONSTANT_MESSAGES.MDU.GATEWAY_EDITED,
          },
        });
        // ? trigger gateway table api to get updated table data.
        queryClient.invalidateQueries(
          queryConstants.GET_MDU_GATEWAY_TABLE_DATA
        );
      },
      onError: () => {
        setIsResetTableDataToAPIData(true);
        toastDispatch({
          type: TOAST_REDUCER_CONSTANTS.SHOW_ERROR_TOAST,
          payload: {
            message: CONSTANT_MESSAGES.MDU.GATEWAY_NOT_EDITED,
          },
        });
      },
    }
  );

  //? gateway post API for updating enable disable status
  const { mutate: gatewayStatusUpdateMutate } = useMutation(
    (data) => updateMDUGatewayStatusData(data),
    {
      onSuccess: () => {
        toastDispatch({
          type: TOAST_REDUCER_CONSTANTS.SHOW_SUCCESS_TOAST,
          payload: {
            message: CONSTANT_MESSAGES.MDU.GATEWAY_STATUS_UPDATED,
          },
        });
        // ? trigger gateway table api to get updated table data.
        queryClient.invalidateQueries(
          queryConstants.GET_MDU_GATEWAY_TABLE_DATA
        );
      },
      onError: () => {
        setIsResetTableDataToAPIData(true);
        toastDispatch({
          type: TOAST_REDUCER_CONSTANTS.SHOW_ERROR_TOAST,
          payload: {
            message: CONSTANT_MESSAGES.MDU.GATEWAY_STATUS_NOT_UPDATED,
          },
        });
      },
    }
  );

  // ? get sources for gateway creation
  const { data: sourceData } = useQuery(
    [queryConstants.GET_MDU_SOURCE_DATA],
    async () => {
      const response = await retrieveMDUSourceData();
      return response;
    },
    {
      refetchOnWindowFocus: false,
      enabled: !!authToken,
    }
  );

  // ? get gateway table data
  const { data: mduTableDataFromAPI, isFetching: isTableDataFetching } =
    useQuery(
      [queryConstants.GET_MDU_GATEWAY_TABLE_DATA],
      async () => {
        const response = await retrieveMDUGatewayTableData({
          factoryId: rxjsState.factoryId,
        });
        return formatGatewayDatafromAPI(response.data);
      },
      {
        enabled: !!authToken && !!rxjsState?.factoryId,
        refetchOnWindowFocus: false,
        onSuccess: (response) => {
          // ? format the api data to match table data
          setGatewayTableData(response);
        },
      }
    );

  // ? modify local table state on action trigger
  const updateTableDataWithNewRow = (modifiedRow, actionType) => {
    let modifiedGatewayTableData = gatewayTableData;
    switch (actionType) {
      case MDU_CONNECTIVITY_CONSTANTS.ACTIONS.ENABLE_DISABLE:
        modifiedGatewayTableData = gatewayTableData.map((gateway) => {
          // ? find the row that needs to be updated, if found, return the modified row.
          if (modifiedRow.gatewayId === gateway.gatewayId) {
            return modifiedRow;
          } else {
            // ? for every other row, send back as it is.
            return gateway;
          }
        });
        break;

      case MDU_CONNECTIVITY_CONSTANTS.ACTIONS.DELETE:
        // ? filter out the tabledata by ignoring the incoming row
        modifiedGatewayTableData = gatewayTableData
          .map((gateway) => {
            if (modifiedRow.gatewayId !== gateway.gatewayId) {
              return gateway;
            }
          })
          .filter((item) => item !== undefined);

      default:
        break;
    }

    // ? update the tabledata state
    setGatewayTableData(modifiedGatewayTableData);
  };

  // ? handle and redirect actions from table rows
  const onActionClick = (row, actionType, isGatewayEnabled) =>
    onActionClickHelper(
      row,
      actionType,
      isGatewayEnabled,
      setIsEdit,
      setSelectedRowForActions,
      setSelectedDropdown,
      setName,
      setDescription,
      setIsModalOpen,
      updateTableDataWithNewRow,
      setIsConfirmModalOpen
    );

  const handleEnableDisableOrDeleteGateway = (_row, actionType) => {
    closeModalAndClearStates(
      setIsModalOpen,
      setButtonClicked,
      setSelectedDropdown,
      setName,
      setDescription,
      setIsEdit,
      setSelectedRowForActions
    );
    switch (actionType) {
      case MDU_CONNECTIVITY_CONSTANTS.ACTIONS.ENABLE_DISABLE:
        const enableDisableParams = createEnableDisableParams(
          selectedRowForActions,
          rxjsState
        );
        gatewayStatusUpdateMutate(enableDisableParams);

        break;
      case MDU_CONNECTIVITY_CONSTANTS.ACTIONS.DELETE:
        deleteGatewayMutate();
        break;

      default:
        break;
    }
  };

  // ? this is to reset the table data whenever the api/websocket for CRUD operations fail,
  // ? or to revert the table data on cancelling a CRUD operation locally.
  useEffect(() => {
    if (isResetTableDataToAPIData) {
      setGatewayTableData(mduTableDataFromAPI);
      setIsResetTableDataToAPIData(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isResetTableDataToAPIData]);

  const isAllGatewaysDisabled = () => {
    return mduTableDataFromAPI?.every(
      (gateway) => gateway.gatewayStatus === false
    );
  };

  useEffect(() => {
    const isConnectivityDisabled =
      mduTableDataFromAPI?.length === 0 || isAllGatewaysDisabled();
    updateRxjsStateWithConnectivityTabStatus(!isConnectivityDisabled);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mduTableDataFromAPI]);

  return (
    <Box
      sx={{
        margin: '0.75em 1.25em 1.25em',
      }}
    >
      {/* // ? Modals */}
      {/* // ? add/edit modal */}
      <AddEditGatewayModal
        data-testid="add-gateway-popup"
        isModalOpen={isModalOpen}
        setModalOpenStatus={setIsModalOpen}
        isEdit={isEdit}
        setIsEdit={setIsEdit}
        selectedDropdown={selectedDropdown}
        setSelectedDropdown={setSelectedDropdown}
        name={name}
        setName={setName}
        description={description}
        setDescription={setDescription}
        sourceList={sourceData?.data}
        gatewayDetails={gatewayTableData}
        gatewayAddConfig={gatewayAddConfig}
        gatewayEditConfig={gatewayEditConfig}
        buttonClicked={buttonClicked}
        setButtonClicked={setButtonClicked}
        selectedRowForActions={selectedRowForActions}
        setSelectedRowForActions={setSelectedRowForActions}
      />
      {/* // ? enable/disable/delete */}
      <ConfirmationPopupModal
        isConfirmModalOpen={isConfirmModalOpen}
        setConfirmModalOpenStatus={setIsConfirmModalOpen}
        actionType={selectedRowForActions.actionType}
        selectedRow={selectedRowForActions.row}
        onSuccessCallBack={handleEnableDisableOrDeleteGateway}
        onErrorCallBack={() => {
          setIsResetTableDataToAPIData(true);
          setSelectedRowForActions({
            row: null,
            actionType: '',
          });
        }}
      />

      {/* // ? Title */}
      <Box
        sx={{
          marginBottom: '1.25em',
        }}
      >
        <Typography variant="h6" data-testid="Title">
          {CONNECTIVITY_MODALS_CONSTANTS.CONNECTIVITY}
        </Typography>
      </Box>
      {/* // ? Table */}
      <StyledTableBox>
        <SMFTable
          loading={isTableDataFetching}
          height="21rem"
          EmptySearchTableLabel={() => ''}
          columns={MDUTableColumns(onActionClick)}
          initialPagination={{
            pageIndex: 0,
            pageSize: 10,
          }}
          customGlobalFilterFn={(row, columnId, filterValue) =>
            globalSearchForMultipleColumns(
              row,
              ['gatewayName', 'gatewayDescription'],
              filterValue
            )
          }
          isDark={null}
          isPaginationEnabled={false}
          rows={setGatewayTableRows(gatewayTableData)}
          tableConfig={{
            footer: true,
            search: true,
            searchPlaceholder: 'Search....',
            sorting: true,
            isPaginationEnabled: true,
            emptyRowsText: CONSTANT_MESSAGES.MDU.NO_TABLE_DATA,
          }}
          TableTopRightComponent={() => (
            <GeneralButton
              data-testid="popup-modal-no-button"
              onClick={() => {
                //? to-do: toggle popup open state
                setIsModalOpen(!isModalOpen);
              }}
              text="Add"
              disable={false}
              hover={false}
              border={false}
              type={BUTTON_TYPE.PRIMARY}
              fontWeight={600}
              fontSize="13px"
              lineHeight="22px"
              borderRadius="4px"
              size="small"
              width="46px"
              height="30px"
            />
          )}
        />
      </StyledTableBox>
    </Box>
  );
};

export default MDUConnectivityContainer;
